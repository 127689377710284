/**
 * Met les heures, minutes etc à 0 ou les minutes etc à 59
 */
export function dateToCompare(d: Date, end?: boolean): Date {
    d = new Date(d);
    if (d != undefined) {
        if (end) {
            d.setUTCHours(23);
            d.setUTCMinutes(59);
            d.setUTCSeconds(59);
            d.setUTCMilliseconds(999);
        } else {
            d.setUTCHours(0);
            d.setUTCMinutes(0);
            d.setUTCSeconds(0);
            d.setUTCMilliseconds(0);
        }
    }

    return d;
}