import {Component} from "../../../../sedestral/framework/interface/component/Component";
import * as s from "./progressbar.scss";
import {IProgressBar} from "./types/IProgressBar";
import {SedestralMachine} from "../../../../sedestral/framework/machine/SedestralMachine";
import {string} from "lib0";

export class ProgressBarComponent extends Component {

    public barComponent: Component;
    public p: IProgressBar;

    constructor(p: IProgressBar) {
        super();
        this.p = p;

        //language=HTML
        this.template = `
            <div data-tooltip="${p.tooltip}" class="${s.globalProgressBar} ${p.white ? s.white : ``}">
                <div class="${s.bar} ${p.color ? s[p.color] : ``}"></div>
                ${p.showValue ? `<div class="${s.percent}">50%</div>` : ``}
            </div>
        `;
    }

    commit() {
        this.barComponent = this.el(s.bar);

        if (this.p.tooltip) {
            super.commitTooltips();
        }

        this.barComponent.setStyle(`width:0%;`);
        this.setBar(this.p.value);

        super.commit();
    }

    setBar(value: number) {
        this.p.value = value;
        if (this.p.autoTooltip) {
            this.clearTooltipListeners();
            this.setTooltip(`${this.p.value}/${this.p.maxValue}`);
        }

        if (this.p.autoColor || this.p.autoColorInvert) {
            this.barComponent.setAttribute("class", s.bar);
            this.barComponent.addClass(s[this.p.autoColorInvert ? this.getColorInvert() : this.getColor()]);
        }

        let width = Math.round(this.p.value * 100 / this.p.maxValue);
        SedestralMachine.requestFrame()(() => {
            this.barComponent.setStyle(`width:${width}%;transition-duration: ${this.p.animationDelay / 1000}s;`);
        });
    }

    getColor(): string {
        const percentage = (this.p.value / this.p.maxValue) * 100;

        if (percentage < 30) {
            return "red";
        } else if (percentage >= 30 && percentage <= 60) {
            return "orange";
        } else {
            return "green";
        }
    }

    getColorInvert(): string {
        const percentage = (this.p.value / this.p.maxValue) * 100;

        if (percentage < 50) {
            return "green";
        } else if (percentage < 65) {
            return "orange";
        } else {
            return "red";
        }
    }
}