import {
    StaticComponent
} from "../../../../../sedestral/router/static/component/StaticComponent";

export class LoaderStatic extends StaticComponent {

    constructor() {
        super();
        this.element = document.querySelector('.globalLoaderBackground:not([globalBg])');
    }

    displayShow() {
        super.displayShow();
        this.setStyle("display:flex;opacity:1;");
    }

    async displayHide() {
        await super.fade("out", 200);
        super.displayHide();
    }

    onHash(): void {
    }
}