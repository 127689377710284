import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import {OfferPaymentActionType} from "../../../../../../models/offer/payment/OfferPaymentActionType";
import {Resources} from "../../../../../../resources/Resources";
import {IOfferPaymentSecretsModel} from "../../../../../../models/offer/payment/IOfferPaymentSecretsModel";
import {
    randomInteger
} from "../../../../../../sedestral/framework/utilities/RandomInteger";

export class OfferStripeComponent extends Component {

    public id: string;
    public secrets: IOfferPaymentSecretsModel;
    public actionType: OfferPaymentActionType;
    public stripe: any;
    public stripeElements: any;

    constructor(secrets: IOfferPaymentSecretsModel, actionType: OfferPaymentActionType) {
        super();
        this.id = "offer-stripe-component" + randomInteger(0, 1000000);
        this.secrets = secrets;
        this.actionType = actionType;

        //language=HTML
        this.template = `
            <div id="${this.id}"></div>`;
    }

    commit() {
        super.commit();
        this.init();
    }

    async init() {

       const stripeJs = await import("@stripe/stripe-js");

        this.stripe = await stripeJs.loadStripe(process.env.STRIPE_PUBLIC_KEY, {
            betas: ["elements_customers_beta_1"],
            apiVersion: "2024-04-10"
        });

        let customerOptions: { ephemeralKey: string; customer: string; };

        if (this.secrets.ephemeralKey) {
            customerOptions = {
                ephemeralKey: this.secrets.ephemeralKey,
                customer: this.secrets.customer,
            }
        }

        this.stripeElements = this.stripe.elements({clientSecret: this.secrets.clientSecret, customerOptions});

        let paymentElement = this.stripeElements.create('payment');
        paymentElement.mount("#" + this.id);
    }

    async payment(): Promise<void> {
        let errorStripe = undefined;
        let successStripe = undefined;

        if (this.actionType === OfferPaymentActionType.PAYMENT) {
            const paymentIntentResult = await this.stripe.confirmPayment({
                elements: this.stripeElements,
                redirect: "if_required",
            });

            errorStripe = paymentIntentResult.error;
            successStripe = paymentIntentResult.paymentIntent;

        } else if (this.actionType === OfferPaymentActionType.INTENT) {

            const setupIntentResult = await this.stripe.confirmSetup({
                elements: this.stripeElements,
                redirect: "if_required",
            });

            errorStripe = setupIntentResult.error;
            successStripe = setupIntentResult.setupIntent;
        }

        if (errorStripe) {
            if (errorStripe.code == "payment_intent_unexpected_state") {
                this.onPaid();
            } else {
                this.onError(errorStripe.code != "incomplete_number" ? (errorStripe.message ?? Resources.t("words.errorgenericErrorMessage")) : undefined);
            }

        } else if (successStripe) {
            this.onPaid();
        }
    }

    onPaid() {

    }

    onError(error?: string) {

    }
}