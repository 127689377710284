export function txtToSearch(txt: string) {
    txt = txt.trim().toLowerCase();
    txt = txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    txt = txt.replace("s", "")
        .replace("x", "")
        .replace("’", "'")
        .replace("‘", "'")
        .replace("´", "'")
        .replace("`", "'");
    return txt;
}