import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import * as s from "./offer-quote.scss";
import {ProductType} from "../../../../../../models/product/ProductType";
import {Resources} from "../../../../../../resources/Resources";
import {OfferQuoteConfirmedComponent} from "./OfferQuoteConfirmedComponent";
import {
    EmptyBasicComponent
} from "../../../../../components/global/empty/EmptyBasicComponent";
import {
    dateFormat
} from "../../../../../../sedestral/framework/utilities/DateFormat";
import {
    ButtonsSaveComponent
} from "../../../../../components/global/buttons/save/ButtonsSaveComponent";
import {OfferService} from "../../../../../../services/offer/OfferService";
import {Network} from "../../../../../../network/Network";
import {PaymentProvider} from "../../../../../../models/payment/PaymentProviderType";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {
    PromptConfirmationComponent
} from "../../../../../components/global/prompts/confirmation/PromptConfirmationComponent";

export class OfferQuoteScheduledComponent extends OfferQuoteConfirmedComponent {

    public paymentProvider: PaymentProvider;

    constructor(productType: ProductType, quote: IOfferDetailsModel) {
        super(productType, quote);

        this.paymentProvider = EntityService.activeSite.paymentProvider;

        //language=HTML
        this.template = `
            <div class="${s.componentOfferQuote}"></div>`;
    }

    commit() {
        super.commit();
    }

    renderAll() {
        this.renderEmpty();
        this.renderOfferContent();
        this.renderPriceDetailsNext();

        if (!this.paymentProvider.directDeleteDefinitiveOffer) {
            this.renderCancel();
        }
    }

    renderEmpty() {
        //language=HTML
        this.append(`
            <div class="${s.emptyContainer}">
                ${this.draw(new EmptyBasicComponent(`<div class="${s.icon}"></div>`, `${Resources.t("words.offerTakeEffectAt")} ${dateFormat(new Date(this.quote.offer.startDate))}`))}
            </div>
        `);
    }

    renderCancel() {
        let buttons = new ButtonsSaveComponent({
            hideCancelButton: true,
            saveText: Resources.t("words.offerCancelSchedule")
        });

        buttons.onSave = async () => {

            let confirmation = new PromptConfirmationComponent(Resources.t("words.offerCancelScheduleConfirmation"));
            confirmation.screenRender();
            confirmation.onContinue = async () => {

                buttons.setLoading();

                try {
                    if (await OfferService.cancel(this.quote.offer.id, true, this)) {
                        Network.router.static.components.notifications.notify(Resources.t("words.actionSuccess"), undefined, 3000, "success");
                    }
                } catch (e) {
                    console.error(e);
                }

                this.onCloseModal();
                await Network.router.pages.reload();
                await confirmation.close();
            }
        }

        //language=HTML
        this.append(`
            <div class="${s.cancel}">
                ${this.draw(buttons)}
            </div>
        `);
    }
}