import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {
    Component
} from "../../../sedestral/framework/interface/component/Component";
import {
    ICommunitySEOKeywordsSearchOutgoing
} from "../../../models/community/seo/keywords/ICommunitySEOKeywordsSearchOutgoing";
import {ICommunitySEOKeywordsIncoming} from "../../../models/community/seo/keywords/ICommunitySEOKeywordsIncoming";
import {
    ICommunitySEORankingIndividualsPositioningModel
} from "../../../models/community/seo/ranking/ICommunitySEORankingIndividualsPositioningModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Services} from "../../Services";
import {
    ICommunitySEOKeywordsPresentationOutgoing
} from "../../../models/community/seo/keywords/ICommunitySEOKeywordsPresentationOutgoing";
import {
    ICommunitySEORankingOverviewModel
} from "../../../models/community/seo/ranking/ICommunitySEORankingOverviewModel";
import {
    ICommunitySEORankingComparativesPositioningModel
} from "../../../models/community/seo/ranking/ICommunitySEORankingComparativesPositioningModel";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";
import {
    ICommunitySEORankingCannibalizationIncoming
} from "../../../models/community/seo/ranking/ICommunitySEORankingCannibalizationIncoming";
import {IPaginationOutgoing} from "../../../models/pagination/IPaginationOutgoing";

export class CommunityKeywordsService {

    public static dispose(): void {

    }

    public static init(): void {
    }

    public static async getKeywordsSite(search: ICommunitySEOKeywordsSearchOutgoing, component: Component): Promise<ICommunitySEOKeywordsIncoming> {
        Services.handleErrors(component, [
            {status: HttpStatus.BAD_REQUEST, message: "none"}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords/site`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async searchKeywords(name: string, search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEOKeywordsIncoming> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/keywords/search/${name}`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async searchKeywordsHistory(communityId: string, component?: Component): Promise<ICommunitySEOKeywordsPresentationOutgoing[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/keywords/search/history/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async createRankingOverview(communityId: string, host: string, owner: boolean, dateStart: number, dateEnd: number, component?: Component): Promise<ICommunitySEORankingOverviewModel[]> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.HOST_EXIST, message: Resources.t("words.domainAlreadyInStats")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/ranking/overview/create/${communityId}/${host}/${owner}/${dateStart}/${dateEnd}`, {}, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getRankingOverview(communityId: string, dateStart: number, dateEnd: number, component?: INetworkComponent): Promise<ICommunitySEORankingOverviewModel[]> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.COMMUNITY, `/community/ranking/overview/${communityId}/${dateStart}/${dateEnd}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data
        }

        if (request.status == HttpStatus.NOT_FOUND) {
            return [];
        }

        return undefined;
    }

    public static async getIndividualsPositioning(id: string, search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEORankingIndividualsPositioningModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/ranking/individualsPositioning/${id}`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getCannibalization(communityId: string, pagination: IPaginationOutgoing, component?: INetworkComponent): Promise<ICommunitySEORankingCannibalizationIncoming> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/ranking/cannibalization/${communityId}`, pagination, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async getComparativePositioning(search: ICommunitySEOKeywordsSearchOutgoing, component?: Component): Promise<ICommunitySEORankingComparativesPositioningModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/ranking/comparativePositioning`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async deleteRanking(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/ranking/${id}`, component);
        return request.status == HttpStatus.OK;
    }
}