import * as s from "./visual-input-states.scss";
import {Component} from "../../../../../sedestral/framework/interface/component/Component";
import {IVisualInputStates} from "./types/IVisualInputStates";
import {MachineInput} from "../../../../../sedestral/framework/machine/input/MachineInput";
import {SedestralMachine} from "../../../../../sedestral/framework/machine/SedestralMachine";
import {AsYouType, parsePhoneNumberFromString} from 'libphonenumber-js'
import {
    VisualSelectBoxComponent
} from "../../../global/selectbox/VisualSelectBoxComponent";
import {Resources} from "../../../../../resources/Resources";
import {filterXSS} from "../../../../../sedestral/framework/utilities/FilterXSS";
import {txtEscape} from "../../../../../sedestral/framework/utilities/TxtEscape";

export class VisualInputStatesComponent extends Component implements MachineInput {

    public p: IVisualInputStates;
    public checker: () => boolean;
    public input: Component;

    public telCountry: any;

    constructor(p: IVisualInputStates) {
        super();
        this.p = p;

        if (this.p.type == "tel") {
            let parser = parsePhoneNumberFromString(p.value ? p.value : "");
            this.telCountry = parser ? parser.country : Resources.getCountryCode().toUpperCase();
        }

        //language=HTML
        this.template = `
            <div class="${s.componentVisualInputStates} ${p.noBorder ? s.noBorder : ""} ${p.bold ? s.bold : ""} ${p.semiBold ? s.semiBold : ""} ${p.outline ? s.outline : ""}">
                <div style="font-size: ${p.fontSize ? p.fontSize : 15}px;left: ${p.paddingLeft != undefined ? p.paddingLeft : (p.type === "tel" ? 80 : 15)}px;"
                     class="${s.title} ${p.noLabel ? s.noLabel : ""}">
                    ${p.placeholder}${p.required ? "*" : ""}
                </div>

                <input style="${p.maxWidth ? `max-width: ${p.maxWidth}px;` : ``}${p.borderRadius ? `border-radius: ${p.borderRadius}px;` : ``}cursor: text;${p.maxHeight ? 'height: calc(100% - 4px) !important;' : ''}font-size: ${p.fontSize ? p.fontSize : 15}px;padding-left: ${p.paddingLeft != undefined ? p.paddingLeft : 15}px; ${p.padding != undefined ? "padding:" + p.padding + "px" : ""}"
                       class="${s.input}"
                       ${p.required ? `required` : ``}
                       ${p.disabled ? `disabled` : ``}
                       ${p.minNumber ? `min="${p.minNumber}"` : ``}
                       ${p.maxNumber ? `max="${p.maxNumber}"` : ``}
                       ${p.value ? `value="${txtEscape(this.formatValue(p.value))}"` : ``}
                       ${p.noLabel && p.placeholder ? `placeholder="${p.placeholder}"` : ``}
                       ${p.type ? `type="${p.type}"` : ``}
                       spellcheck="false"/>
            </div>
        `;

        SedestralMachine.inputs.push(this);
        this.onRemove(() => SedestralMachine.inputs.splice(SedestralMachine.inputs.indexOf(this)));
    }

    commit() {
        this.input = this.el(s.input);

        if (this.p?.focus) {
            this.input.focusAtEnd();
            this.addClass(s.focused);
        }

        if (this.input.getValue().length > 0) {
            this.addClass(s.focused);
        }


        this.initEvents();
        if (this.p.type == "tel") {
            this.initTel();
        }

        if (this.p.autoWidth) {
            this.initAutoWidth();
        }

        if (this.p.saveMode) {
            this.initSave();
        }

        super.commit();
    }

    /**
     * init
     */

    initEvents() {
        this.checker = () => {
            const inputValueLength = this.input.getValue().length;
            if (this.p.required && inputValueLength == 0) {
                return false;
            }

            if (this.p.minLength && inputValueLength < this.p.minLength) {
                return false;
            }

            if (this.p.minLength > 0 && this.input.getValue().replaceAll(" ", "").length == 0)
                return false;


            return !(this.p.maxLength && inputValueLength > this.p.maxLength);
        }


        this.input.onFocus(() => this.addClass(s.focused));
        this.input.onBlur(() => {
            if (this.input.getValue().length <= 0) {
                this.removeClass(s.focused)
            } else {
                this.addClass(s.focused);
            }
        });

        this.onKeyUp((key) => {
            this.input.setValue(this.formatValue(this.input.getValue()));
            if (key.key != "Enter")
                this.check();
        });
    }

    initTel() {
        let selectBox = new VisualSelectBoxComponent({
            entries: Resources.countries.map(value => {
                return {value: value['a2'].toUpperCase(), label: `${value['n']} (${value['a2'].toUpperCase()})`}
            }), selectedValue: this.telCountry, search: true, width: 220, previewIsValue: true
        });

        //language=HTML
        this.prepend(`
            <div class="${s.select}">
                ${this.draw(selectBox)}
            </div>
        `);

        selectBox.onSelect = (entry) => {
            this.telCountry = entry.value;
            this.input.setValue(this.formatValue(this.input.getValue()));
            this.check();
        }
    }

    initAutoWidth() {
        this.setStyle(`width: auto;`);
        this.addListener("input", () => {
            this.input.setStyle(`width: ${this.contentWidth()}px`);
        });

        this.input.setStyle(`width: ${this.contentWidth()}px`);
    }

    initSave() {
        let edited = false;
        let focused = false;
        this.input.onClick(() => focused = true);
        this.input.onKeyUp(() => edited = true);
        this.input.onHotKey("Enter", () => {
            this.input.getHTMLElement().blur();
            this.onSave();
        });
        this.input.onOutsideClick(() => {
            if (focused && edited) {
                this.onSave(true);
            }

            edited = false;
            focused = false;
        });
    }

    /**
     * value
     */
    getValue(): string {
        if (this.p.type == "tel") {
            let formatter = new AsYouType(this.telCountry);
            formatter.input(this.input.getValue());
            return formatter.getNumber()?.number;
        }
        return this.input.getValue();
    }

    setValue(value: string): string {
        if (value.length > 0) {
            this.addClass(s.focused);
        } else {
            this.removeClass(s.focused);
        }
        this.input.setValue(value);
        if (this.p.autoWidth) {
            this.input.setStyle(`width: ${this.contentWidth()}px`);
        }
        return filterXSS(value);
    }

    formatValue(value: string): string {
        if (this.p.type == "tel") {
            let formatter = new AsYouType(this.telCountry);
            let parser = parsePhoneNumberFromString(value);
            return parser ? parser.formatNational() : formatter.input(value);
        }

        return value;
    }

    /**
     * width
     */

    contentWidth(placeholder?: string): number {
        let temp = document.createElement('span');
        temp.style.font = window.getComputedStyle(this.input.getHTMLElement()).font;
        temp.style.visibility = 'hidden';
        temp.innerHTML = (placeholder ? placeholder : this.input.getValue()).replace(/ /g, '&nbsp;');

        document.body.appendChild(temp);

        let width = temp.offsetWidth + (this.p.paddingLeft ? (this.p.paddingLeft * 2) : (this.getPaddingWidth() * 10)) + 2;
        document.body.removeChild(temp);

        let placeholderWidth = 0;
        if (!placeholder && this.p.placeholder) {
            placeholderWidth = this.contentWidth(this.p.placeholder);
        }

        return ((this.input.getValue().length == 0 && width < placeholderWidth && placeholderWidth != 0) ? placeholderWidth : width) + 15;
    }

    /**
     * state
     */
    setStateError() {
        this.addClass(s.error);
    }

    removeState() {
        this.removeClass(s.error);
    }

    /**
     * check
     */
    check(): boolean {
        if (this.checker() && this.checkerLocal()) {
            this.removeState();
        } else {
            this.setStateError();
        }

        return this.isValid();
    }

    checkerLocal(): boolean {
        if (this.p.type == "tel") {
            let type = new AsYouType(this.telCountry);
            type.input(this.input.getValue());
            return type.isValid();
        }
        return true;
    }

    isValid() {
        return !this.hasClass(s.error);
    }

    /**
     * focus
     */
    isFocus(): boolean {
        return (document.activeElement == this.input.getHTMLElement());
    }

    focus() {
        this.input.focusAtEnd();
    }

    /**
     * other
     */

    public showShadowTooltip(template: any, color?: string) {
        this.input.showShadowTooltip(template, color);
    }

    /**
     * keys
     */
    onKeyUp(func: (e?: any) => void, delay?: number): SedestralMachine {
        return this.input.onKeyUp(func, delay);
    }

    onKey(func: (e?: any) => void, delay?: number): SedestralMachine {
        return this.input.onKey(func, delay);
    }

    /**
     * override
     */

    onSave(outsideClick?: boolean) {

    }
}