import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./site-address.scss";
import {CountryPickerComponent} from "../../country/picker/CountryPickerComponent";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {
    VisualInputStatesComponent
} from "../../../../../components/visual/input/states/VisualInputStatesComponent";
import {Resources} from "../../../../../../resources/Resources";
import {ISiteAddressModel} from "../../../../../../models/site/address/ISiteAddressModel";
import {SiteTaxComponent} from "../tax/SiteTaxComponent";

export class SiteAddressComponent extends Component {

    public nameInput: VisualInputStatesComponent;
    public line1Input: VisualInputStatesComponent;
    public line2Input: VisualInputStatesComponent;
    public postalCodeInput: VisualInputStatesComponent;
    public cityInput: VisualInputStatesComponent;
    public stateInput: VisualInputStatesComponent;
    public countryPicker: CountryPickerComponent;

    public taxPicker: SiteTaxComponent;
    public taxInput: VisualInputStatesComponent;

    constructor() {
        super();
        this.countryPicker = new CountryPickerComponent(EntityService.activeSite.address?.country ? EntityService.activeSite.address?.country : Resources.getCountryCode());
        this.nameInput = new VisualInputStatesComponent({
            required: true,
            placeholder: Resources.t("words.name"),
            value: EntityService.activeSite.address?.name
        });
        this.line1Input = new VisualInputStatesComponent({
            required: true,
            placeholder: Resources.t("words.address"),
            value: EntityService.activeSite.address?.line1
        });
        this.line2Input = new VisualInputStatesComponent({
            placeholder: Resources.t("words.addressDetails"),
            value: EntityService.activeSite.address?.line2
        });
        this.postalCodeInput = new VisualInputStatesComponent({
            required: true,
            placeholder: Resources.t("words.zipCode"),
            value: EntityService.activeSite.address?.postalCode
        });
        this.cityInput = new VisualInputStatesComponent({
            required: true,
            placeholder: Resources.t("words.city"),
            value: EntityService.activeSite.address?.city
        });
        this.stateInput = new VisualInputStatesComponent({
            placeholder: Resources.t("words.addressState"),
            value: EntityService.activeSite.address?.state
        });
        this.taxInput = new VisualInputStatesComponent({
            placeholder: Resources.t("words.number"),
            value: EntityService.activeSite.address?.tax?.code
        });
        this.taxPicker = new SiteTaxComponent();

        //language=HTML
        this.template = `
            <div class="${s.componentSiteAddress}">
                <div class="${s.inputContainer}">
                    ${this.draw(this.nameInput)}
                </div>
                <div>
                    <div class="${s.inputContainer}">
                        ${this.draw(this.line1Input)}
                    </div>
                    <div class="${s.inputContainer}">
                        ${this.draw(this.line2Input)}
                    </div>
                </div>
                <div class="${s.doubleInput}">
                    <div class="${s.inputContainer}">
                        ${this.draw(this.postalCodeInput)}
                    </div>
                    <div class="${s.inputContainer}">
                        ${this.draw(this.cityInput)}
                    </div>
                </div>
                <div class="${s.doubleInput}">
                    <div class="${s.inputContainer}">
                        ${this.draw(this.stateInput)}
                    </div>
                    <div class="${s.inputContainer}">
                        ${this.draw(this.countryPicker)}
                    </div>
                </div>
                <div class="${s.label}">
                    ${Resources.t("words.optionalTaxNumber")}
                </div>
                <div class="${s.doubleInput}">
                    <div class="${s.inputContainer}">
                        ${this.draw(this.taxPicker)}
                    </div>
                    <div class="${s.inputContainer}">
                        ${this.draw(this.taxInput)}
                    </div>
                </div>
            </div>
        `;
    }

    getAddress(): ISiteAddressModel {
        return {
            city: this.cityInput.getValue(),
            country: this.countryPicker.selectBox.getSelection().value as string,
            line1: this.line1Input.getValue(),
            line2: this.line2Input.getValue(),
            name: this.nameInput.getValue(),
            postalCode: this.postalCodeInput.getValue(),
            state: this.stateInput.getValue(),
            tax: this.taxInput.getValueTrim().length > 0 ? {
                code: this.taxInput.getValue(),
                type: this.taxPicker.getSelectedTax().id
            } : undefined
        };
    }
}