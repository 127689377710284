import {Network} from "../../../network/Network";
import {SedestralRouter} from "../../../sedestral/router/SedestralRouter";
import {LoaderStatic} from "./static/loaders/LoaderStatic";
import {SlidingLoaderStatic} from "./static/loaders/sliding/SlidingLoaderStatic";
import {NotificationsStatic} from "./static/notifications/NotificationsStatic";
import {PageLoaderStatic} from "./static/loaders/page/PageLoaderStatic";
import {ProductType} from "../../../models/product/ProductType";
import {TwoFactorStatic} from "./static/twofactor/TwoFactorStatic";
import {
    ISedestralRouterConfig
} from "../../../sedestral/router/types/ISedestralRouterConfig";
import {isMobile} from "../../../sedestral/framework/utilities/IsMobile";
import {
    SedestralInterface
} from "../../../sedestral/framework/interface/SedestralInterface";
import {
    SedestralStorage
} from "../../../sedestral/framework/memory/SedestralStorage";
import {PermissionsService} from "../../../services/permissions/PermissionsService";
import {MobileNavigationStatic} from "./static/navigation/mobile/MobileNavigationStatic";

export class PanelRouter extends SedestralRouter {

    async init(config?: ISedestralRouterConfig): Promise<void> {
        super.init(config);
        super.initStarted();

        if (Network.logged && this.static.components.navigation) {
            this.static.components.navigation.init();
        }

        if (isMobile())
            SedestralInterface.body.addClass("mobile");

        if (PermissionsService.canProduct(ProductType.INBOX))
            SedestralInterface.body.addClass("navigation");
    }

    registerPages() {
        this.pages.registerPage("/b", async () => {
            return new (await this.importPage(() => import("./pages/blog/BlogPage"), ProductType.SEDESTRAL)).BlogPage();
        });
        this.pages.registerPage("/404", async () => {
            return new (await this.importPage(() => import("./pages/global/notfound/NotFoundPage"))).NotFoundPage();
        });
        this.pages.registerPage("/login", async () => {
            return new (await this.importPage(() => import("./pages/login/LoginPage"))).LoginPage();
        });
        this.pages.registerPage("/signup", async () => {
            return new (await this.importPage(() => import("./pages/signup/SignupPage"))).SignupPage();
        });
        this.pages.registerPage("/invitation", async () => {
            return new (await this.importPage(() => import("./pages/invitation/InvitationPage"))).InvitationPage();
        });
        this.pages.registerPage("/password", async () => {
            return new (await this.importPage(() => import("./pages/password/lost/PasswordLostPage"))).PasswordLostPage();
        });
        this.pages.registerPage("/test2", async () => {
            return new (await this.importPage(() => import("./pages/test/Test2Page"))).Test2Page();
        });
        this.pages.registerPage("/admin", async () => {
            return new (await this.importPage(() => import("./pages/admin/AdminPage"))).AdminPage();
        });
        this.pages.registerPage("/admin-tracking", async () => {
            return new (await this.importPage(() => import("./pages/admin/AdminTrackingPage"))).AdminTrackingPage();
        });

        this.pages.path404 = "/b";
        this.pages.pathHome = "/b";
        this.pages.pathLogin = "/login";
    }

    registerStatic() {
        this.static.register("loader", new LoaderStatic());
        this.static.register("pageLoader", new PageLoaderStatic());
        this.static.register("slidingLoader", new SlidingLoaderStatic());
        this.static.register("notifications", new NotificationsStatic());

        if (isMobile())
            this.static.register("navigation", new MobileNavigationStatic());
        this.static.register("twoFactor", new TwoFactorStatic());
    }

    onLogin() {
        this.pages.redirect(this.pages.pathHome);
        if (this.static.components.navigation)
            this.static.components.navigation.init();
    }

    onLogout() {
        SedestralStorage.setItem("lastRoute", location.pathname);
        setTimeout(async () => {
            await this.pages.redirect(this.pages.pathLogin);
            this.static.components.navigation.displayHide();
        }, 1500);
    }
}