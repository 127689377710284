import {StatisticsSplitType} from "../../products/panel/components/components/statistics/types/StatisticsSplitType";
import {Resources} from "../../resources/Resources";
import {IStatisticsValueModel} from "../../models/statistics/IStatisticsValueModel";
import {dateHourFormat} from "../../sedestral/framework/utilities/DateFormat";
import {
    dateCountDaysBetweenDates
} from "../../sedestral/framework/utilities/DateCountDaysBetweenDates";
import {datePrevious} from "../../sedestral/framework/utilities/DatePrevious";
import {dateSundays} from "../../sedestral/framework/utilities/DateSundays";
import {
    dateMonthsBetweenDates
} from "../../sedestral/framework/utilities/DateMonthsBetweenDates";
import {dateDisplay} from "../../sedestral/framework/utilities/DateDisplay";
import {dateToCompare} from "../../sedestral/framework/utilities/DateToCompare";
import {
    dateWeekOfTheYear
} from "../../sedestral/framework/utilities/DateWeekOfTheYear";

export class StatisticsService {
    public static init(): void {

    }

    public static dispose(): void {

    }

    /**
     * values to charts
     */

    public static cleanValues(values: [any[], any[]], oldValues: [any[], any[]] | any[][]): [[string[], string[]], [string[], string[]]] {
        if (oldValues[0].length > values[0].length) {
            oldValues[0].splice(0, (oldValues[0].length - values[0].length));
        }

        if (values[0].length > oldValues[0].length) {
            values[0].splice(0, (values[0].length - oldValues[0].length));
        }

        return [values, oldValues as [string[], string[]]];
    }

    public static typeValues(type: StatisticsSplitType, startTime: number, endTime: number, values: IStatisticsValueModel[]): [string[], string[]] {
        if (values == undefined) {
            return [[], []];
        }

        switch (type) {
            case StatisticsSplitType.HOUR:
                return StatisticsService.hourValues(startTime, endTime, values);
            case StatisticsSplitType.WEEK:
                return StatisticsService.weeksValues(startTime, endTime, values);
            case StatisticsSplitType.MONTH:
                return StatisticsService.monthsValues(startTime, endTime, values);
        }

        return StatisticsService.dayValues(startTime, endTime, values);
    }

    public static hourValues(startTime: number, endTime: number, values: IStatisticsValueModel[]): [string[], string[]] {
        let from = dateToCompare(new Date(startTime));
        let to = dateToCompare(new Date(endTime), true);
        let daysCount = dateCountDaysBetweenDates(from, to);
        let resultValues = [];
        let resultKeys = [];
        let now = Date.now();
        for (let i = 0; i < daysCount; i++) {
            let dayDate = new Date(from);
            for (let h = 0; h < 24; h++) {
                dayDate.setHours(h);
                if (dayDate.getTime() <= now) {
                    let value = values.find(v => v.dateKey == dayDate.getTime());
                    resultValues.push(value == undefined ? 0 : value.value);
                    resultKeys.push(dayDate.getTime());
                }
            }
            from.setDate(from.getDate() + 1);
        }

        return [resultValues, resultKeys];
    }

    public static dayValues(startTime: number, endTime: number, values: IStatisticsValueModel[]): [string[], string[]] {
        let entries = {};
        let resultValues = [];
        let resultKeys = [];

        let from = dateToCompare(new Date(startTime));
        let to = dateToCompare(new Date(endTime), true);
        let daysCount = dateCountDaysBetweenDates(from, to);

        values.sort((a, b) => a.dateKey > b.dateKey ? 1 : -1);
        values.forEach(value => entries[dateToCompare(new Date(value.dateKey)).getTime()] = value.value);

        while (resultValues.length < daysCount) {
            resultValues.push(entries[from.getTime()] == undefined ? 0 : entries[from.getTime()]);
            resultKeys.push(from.getTime());
            from.setDate(from.getDate() + 1);
        }
        return [resultValues, resultKeys];
    }

    public static weeksValues(startTime: number, endTime: number, values: IStatisticsValueModel[]): [string[], string[]] {
        let sundays = dateSundays(startTime, endTime);
        let resultValues = [];
        let resultKeys = [];
        for (let sunday of sundays) {
            let weekResult = dateWeekOfTheYear(sunday);
            let value = values.find(v => v.dateKey == (weekResult[0] + weekResult[1]));
            resultValues.push(value ? value.value : 0);
            resultKeys.push(sunday.getTime());
        }

        return [resultValues, resultKeys];
    }

    public static monthsValues(startTime: number, endTime: number, values: IStatisticsValueModel[]): [string[], string[]] {
        let months = dateMonthsBetweenDates(startTime, endTime);
        let resultValues = [];
        let resultKeys = [];
        for (let month of months) {
            let value = values.find(v => v.dateKey == month[0] + month[1]);
            resultValues.push(value ? value.value : 0);
            resultKeys.push(new Date(month[0], month[1]).getTime());
        }

        return [resultValues, resultKeys];
    }

    /**
     * format
     */
    public static valueFormat(date: Date, type: StatisticsSplitType) {
        switch (type) {
            case StatisticsSplitType.DAY:
                return dateDisplay(date, Resources.language, {
                    month: "short",
                    day: "numeric",
                    year: "numeric"
                });
            case StatisticsSplitType.MONTH:
                return dateDisplay(date, Resources.language, {
                    month: "short",
                    year: "numeric"
                });
            case StatisticsSplitType.WEEK:
                let endDate = new Date(date);
                endDate.setDate(endDate.getDate() + 7);
                return dateDisplay(date, Resources.language, {
                    month: "short",
                    day: "numeric"
                }) + " - " + dateDisplay(endDate, Resources.language, {
                    month: "short",
                    day: "numeric"
                });
            case StatisticsSplitType.HOUR:
                date.setMinutes(0);
                return dateHourFormat(date);
        }
    }

    /**
     * time
     */
    public static startTime(previousDays: number) {
        return dateToCompare(datePrevious(previousDays)).getTime();
    }

    public static endTime(startTime?: number): number {
        if (startTime) {
            let date = new Date(startTime);
            date.setDate(date.getDate() - 1);
            return dateToCompare(date, true).getTime();
        } else {
            return dateToCompare(new Date(), true).getTime();
        }
    }
}