import {IDateIntervalPickerPurpose} from "./IDateIntervalPickerPurpose";
import {Resources} from "../../../../../../../resources/Resources";
import {
    dateCountDaysBetweenDates
} from "../../../../../../../sedestral/framework/utilities/DateCountDaysBetweenDates";
import {datePrevious} from "../../../../../../../sedestral/framework/utilities/DatePrevious";
import {dateToCompare} from "../../../../../../../sedestral/framework/utilities/DateToCompare";

export class DateIntervalPickerPurposes {

    public static basePurposes: IDateIntervalPickerPurpose[];
    public static comparatorPurposes: IDateIntervalPickerPurpose[];

    public static init() {
        DateIntervalPickerPurposes.basePurposes = [
            {value: "today", name: Resources.t("words.todayCalendar"), show: true},
            {value: "yesterday", name: Resources.t("words.yesterday"), show: true},
            {value: "week", name: Resources.r("words.lastDays", {count: 7}), show: true},
            {value: "4week", name: Resources.r("words.lastWeeks", {count: 4}), show: true},
            {value: "3month", name: Resources.r("words.lastMonths", {count: 3}), show: true},
            {value: "6month", name: Resources.r("words.lastMonths", {count: 6}), show: true},
            {value: "12month", name: Resources.r("words.lastMonths", {count: 12}), show: true}
        ];
        DateIntervalPickerPurposes.comparatorPurposes = [
            {value: "lastPeriod", name: Resources.t("words.lastPeriod"), show: true},
            {value: "lastWeek", name: Resources.t("words.lastWeek"), show: true},
            {value: "lastMonth", name: Resources.t("words.lastMonth"), show: true},
            {value: "lastTrimester", name: Resources.t("words.lastTrimester"), show: true},
            {value: "lastSemester", name: Resources.t("words.lastSemester"), show: true},
            {value: "lastYear", name: Resources.t("words.lastYear"), show: true},
            {value: "undefined", name: Resources.t("words.noComparison"), show: true}
        ];
    }

    /**
     * calcs
     */
    public static baseCalc(purposeValue: string): Date[] {
        switch (purposeValue) {
            case "today":
                return [new Date(), new Date()];
            case "yesterday":
                return [datePrevious(1), datePrevious(1)];
            case "week":
                return [datePrevious(6), new Date()];
            case "4week":
                return [datePrevious(28), new Date()];
            case "3month":
                let month3 = new Date();
                month3.setMonth(month3.getMonth() - 3);
                return [month3, new Date()];
            case "6month":
                let month6 = new Date();
                month6.setMonth(month6.getMonth() - 6);
                return [month6, new Date()];
            case "12month":
                let month12 = new Date();
                month12.setMonth(month12.getMonth() - 12);
                return [month12, new Date()];
        }
    }

    public static comparatorCalc(purposeValue: string, firstDate: Date, secondDate: Date): Date[] {
        let comparatorDays = dateCountDaysBetweenDates(firstDate, secondDate, true);
        let difference = 0;
        firstDate = dateToCompare(firstDate);
        secondDate = dateToCompare(secondDate, true);
        switch (purposeValue) {
            case "lastPeriod":
                return [datePrevious(comparatorDays + 1, firstDate), datePrevious(comparatorDays + 1, secondDate)];
            case "undefined":
                return [undefined, undefined];
            case "lastWeek":
                return [datePrevious(7, firstDate), datePrevious(7, secondDate)];
            case "lastMonth":
                firstDate.setMonth(firstDate.getMonth() - 1);
                secondDate.setMonth(secondDate.getMonth() - 1);
                difference = (comparatorDays - dateCountDaysBetweenDates(firstDate, secondDate, true));
                firstDate.setDate(firstDate.getDate() - difference);
                return [firstDate, secondDate];
            case "lastTrimester":
                firstDate.setMonth(firstDate.getMonth() - 3);
                secondDate.setMonth(secondDate.getMonth() - 3);
                difference = (comparatorDays - dateCountDaysBetweenDates(firstDate, secondDate, true));
                firstDate.setDate(firstDate.getDate() - difference);
                return [firstDate, secondDate];
            case "lastSemester":
                firstDate.setMonth(firstDate.getMonth() - 6);
                secondDate.setMonth(secondDate.getMonth() - 6);
                difference = (comparatorDays - dateCountDaysBetweenDates(firstDate, secondDate, true));
                firstDate.setDate(firstDate.getDate() - difference);
                return [firstDate, secondDate];
            case "lastYear":
                firstDate.setFullYear(firstDate.getFullYear() - 1);
                secondDate.setFullYear(secondDate.getFullYear() - 1);
                difference = (comparatorDays - dateCountDaysBetweenDates(firstDate, secondDate, true));
                firstDate.setDate(firstDate.getDate() - difference);
                return [firstDate, secondDate];
        }
    }

    /**
     * find
     */

    public static basePurpose(value: string) {
        return this.basePurposes.filter(v => v.value == value)[0];
    }

    public static comparatorPurpose(value: string) {
        return this.comparatorPurposes.filter(v => v.value == value)[0];
    }


}