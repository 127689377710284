import {
    VisualModalComponent
} from "../../../../../components/visual/modal/VisualModalComponent";
import {OfferComponent} from "../OfferComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import {Resources} from "../../../../../../resources/Resources";

export class OfferModalComponent extends VisualModalComponent {

    public details?: IOfferDetailsModel;
    public productType: ProductType;

    constructor(productType: ProductType, details?: IOfferDetailsModel) {
        super();
        this.productType = productType;
        this.modalStyle = "width:1160px;height: 93%;"
        //language=HTML
        this.name = Resources.t("words.offerPricing");

        let component = new OfferComponent(this.productType, details);
        component.onCloseModal = () => this.close();
        this.modalTemplate = this.draw(component);
    }

    commit() {
        super.commit();
        this.headContainer.setStyle(`border-bottom: 1px solid var(--grey-sur);height: 44px;`);
        this.contentContainer.setStyle(`height: calc(100% - 25px);`);
    }
}