import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./offer-grid.scss";
import {IOfferContentsTable, IOfferContentsTableIcon} from "../content/types/IOfferContentsTable";
import {IOfferProductTemplateModel} from "../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {OfferContents} from "../content/types/OfferContents";
import {OfferTemplateComponent} from "../template/OfferTemplateComponent";
import {IOfferPeriodTypeModel} from "../../../../../../models/offer/IOfferPeriodTypeModel";

export class OfferGridComponent extends Component {

    public templates: IOfferProductTemplateModel[];
    public period: IOfferPeriodTypeModel;
    public selectedTemplateId: string;
    public table: IOfferContentsTable[];

    public templatesComponents: OfferTemplateComponent[];

    constructor(templates: IOfferProductTemplateModel[], period: IOfferPeriodTypeModel, selectedTemplateId?: string) {
        super();
        this.templates = templates;
        this.period = period;
        this.selectedTemplateId = selectedTemplateId;
        this.table = OfferContents.table(templates);
        this.templatesComponents = [];

        //language=HTML
        this.template = `
            <div class="${s.componentOfferGrid}"></div>`;
    }

    commit() {
        this.renderTemplates();
        this.table.forEach(tableLine => this.renderLine(tableLine));
        super.commit();
    }

    renderTemplates() {
        //language=HTML
        let line = this.append(`
            <div class="${s.line}">
                <div class="${s.entry}"></div>
            </div>`);

        this.templates.forEach(template => {
            let templateComponent = new OfferTemplateComponent(template, this.period, this.table);
            templateComponent.onPick = () => {
                this.templatesComponents.forEach(value => value.remSelected());
                templateComponent.setSelected();
                this.onPick(template);
            }

            //language=HTML
            line.append(`
                <div class="${s.entry}">
                    ${line.draw(templateComponent)}
                </div>
            `);

            this.templatesComponents.push(templateComponent);
            if (template.id == this.selectedTemplateId)
                templateComponent.setSelected();
        });
    }

    renderLine(tableLine: IOfferContentsTable) {
        //language=HTML
        let line = this.append(`
            <div class="${s.line}"></div>`);
        //language=HTML
        let entryDescription = line.append(`
            <div class="${s.entry}">
                <div class="${s.title}">
                    ${tableLine.name}
                </div>
                <div class="${s.description}">
                    ${tableLine.description}
                </div>
            </div>
        `);

        if (tableLine.icons) {
            //language=HTML
            entryDescription.append(`
                <div class="${s.icons}">
                    ${tableLine.icons.map(value => `<div class="${s.descIcon} ${s[IOfferContentsTableIcon[value].toLowerCase()]}"></div>`).join("")}
                </div>
            `);
        }

        tableLine.entries.forEach(tableLineEntry => {
            switch (tableLine.type) {
                case "text":
                    //language=HTML
                    line.append(`
                        <div class="${s.entry}">
                            <div class="${s.value}">
                                ${tableLineEntry.value}
                            </div>
                        </div>
                    `);
                    break;
                case "bool":
                    //language=HTML
                    line.append(`
                        <div class="${s.entry}">
                            ${tableLineEntry.value ? `<div class="${s.boolTrue}"><div class="${s.icon}"></div></div>` : `<div class="${s.boolFalse}"></div>`}
                        </div>
                    `);
                    break;
                case "textBool":
                    //language=HTML
                    line.append(`
                        <div class="${s.entry}">
                            ${tableLineEntry.value ? `<div class="${s.boolTrue}"><div class="${s.icon}"></div></div>` : `<div class="${s.boolFalse}"></div>`}
                            ${tableLineEntry.value ? `<div class="${s.description}">${tableLineEntry.value}</div>` : ``}
                        </div>
                    `);
                    break;
            }
        });
    }

    setPeriod(period: IOfferPeriodTypeModel) {
        this.templatesComponents.forEach(value => value.setPeriod(period));
    }

    onPick(template: IOfferProductTemplateModel) {

    }
}