import {Component} from "../../../../sedestral/framework/interface/component/Component";
import * as s from "./modal.scss";
import {SedestralInterface} from "../../../../sedestral/framework/interface/SedestralInterface";
import {Network} from "../../../../network/Network";
import {isMobile} from "../../../../sedestral/framework/utilities/IsMobile";
import {SedestralMachine} from "../../../../sedestral/framework/machine/SedestralMachine";

export class VisualModalComponent extends Component {
    public modalTemplate: string;
    public modalStyle: string;
    public name: string;
    public headMargin: boolean;

    public headContainer: Component;
    public contentContainer: Component;
    public modalContainer: Component;
    public nameContainer: Component;
    public boxContainer: Component;
    public visualModal: Component;
    public modalHeight: number;

    public closeButton: Component;

    public fixed: boolean;
    public fixedWithTitle: boolean;
    public autoResize: boolean;

    constructor() {
        super();
        this.modalTemplate = `<div>template</div>`;
        this.modalStyle = "width:360px;"
        this.name = "name";

        this.template = `
            <div class="${s.visualModal}">
                <div class="${s.box}">
                    <div class="${s.modalHead}">
                        <div class="${s.name}"></div>
                        <div class="${s.CloseButton} ${s.round}">
                            <div class="${s.icon}"></div>
                        </div>
                    </div>
                    <div class="${s.modalContent}"></div>
                </div>
            </div>
        `;
    }

    commit(): void {
        this.visualModal = this.el(s.visualModal);
        this.headContainer = this.el(s.modalHead);
        this.boxContainer = this.el(s.box);
        this.nameContainer = this.el(s.name);
        this.contentContainer = this.el(s.modalContent);
        this.closeButton = this.el(s.CloseButton);

        this.nameContainer.setHtml(this.name);
        this.modalContainer = this.contentContainer.append(this.modalTemplate);

        this.boxContainer.onOutsideClick(() => {
            if (!this.fixed && !this.fixedWithTitle)
                this.close();
        });
        this.closeButton.onClick(() => {
            this.close();
        });

        if (this.modalStyle) {
            this.boxContainer.setStyle(this.modalStyle);
        }

        if (this.headMargin) {
            this.headContainer.setStyle(`margin-bottom:15px;`);
        }

        if (this.fixed)
            this.headContainer.remove();

        if (this.fixedWithTitle) {
            if (!isMobile())
                this.setStyle(`background: white;`);
            this.closeButton.remove();
            Network.router.pages.onPopState(this, () => this.close());
        }

        SedestralMachine.requestFrame()(() => this.resize());

        if (this.autoResize)
            this.onResize(() => this.timeOut(() => this.resize(), 50));

        this.animateOpen();
        super.commit();
    }

    resize() {
        let fullHeight = this.modalContainer.getHeightCalculated();
        if (this.modalHeight != fullHeight) {
            this.modalHeight = fullHeight;

            if ((fullHeight + 60) > window.innerHeight) {
                this.boxContainer.setStyle(`height: ${window.innerHeight - 80}px;`);
            } else {
                this.boxContainer.setStyle(`height:${fullHeight + 36}px`);
            }
        }

    }

    async close(): Promise<void> {
        await this.animateClose();
        this.onClose();
        if (!this.isNull()) {
            this.remove();
        }
    }

    screenRender() {
        SedestralInterface.body.render(this);
    }

    screenPreRender() {
        SedestralInterface.body.prerender(this);
    }

    excludeOutsideClickComponent(component: Component) {
        this.boxContainer.excludeOutsideClickComponent(component);
    }

    /**
     * animate
     */
    public async animateOpen(): Promise<void> {
        if (isMobile()) {
            this.displayShow();
            this.boxContainer.setStyle(`transform:translateY(100%) scale(1);opacity:1;`);
            await this.boxContainer.translate({translateY: 0, delay: 250});
        } else {
            await this.boxContainer.scale(0.8, 1, 80);
            await this.boxContainer.fade("in", 100);
        }

        this.boxContainer.timeOut(() => {
            this.boxContainer.setStyle("transform:unset;");
        }, 150);
    }

    public async animateClose(): Promise<void> {
        if (isMobile()) {
            await this.boxContainer.wait([
                this.boxContainer.translate({translateY: 150, delay: 80}),
                this.boxContainer.fade("out", 80),
                this.fade("out", 80)
            ]);
        } else {
            await this.boxContainer.wait([
                this.boxContainer.scale(1, 0.9, 50),
                this.boxContainer.fade("out", 80),
                this.fade("out", 80)
            ]);
        }
    }

    /**
     * override
     */

    public onClose(): void {

    }
}