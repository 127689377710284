import {TwoFactorComponent} from "../TwoFactorComponent";
import {
    VisualModalComponent
} from "../../../../../components/visual/modal/VisualModalComponent";
import {Resources} from "../../../../../../resources/Resources";

export class TwoFactorModalComponent extends VisualModalComponent {

    constructor(email: string, response: any) {
        super();
        this.name = Resources.t("words.doubleVerification");
        let component = new TwoFactorComponent(email, response);
        this.modalTemplate = this.draw(component);

        component.onVerify = () => this.close();
        component.onRetry = () => this.close();
    }
}
