import {INetworkSocketEvent} from "./types/INetworkSocketEvent";
import {INetworkSocket} from "./types/INetworkSocket";
import {
    SedestralMachine
} from "../../sedestral/framework/machine/SedestralMachine";

export class NetworkSocketConnection {

    public events: INetworkSocketEvent[] = [];
    public disconnectEvents: INetworkSocketEvent[] = [];
    public connectEvents: INetworkSocketEvent[] = [];
    public errorEvents: INetworkSocketEvent[] = [];

    public ws: WebSocket;
    public pingInterval: any;
    public networkOnLogout: boolean;

    constructor(options: INetworkSocket, host: string) {
        let site = options.siteId ? `&site=${options.siteId}` : ``;
        this.ws = new WebSocket(`${host}/?token=${options.activeToken}&type=${options.entityType}&EIO=3&transport=websocket${site}`);
        this.bind();
    }

    public bind() {
        this.ws.onclose = (data) => {
            this.disconnectEvents.forEach(value => value.func(data));
            clearInterval(this.pingInterval);
        }
        this.ws.onopen = (data) => {
            clearInterval(this.pingInterval);
            this.pingInterval = setInterval(() => {
                this.ws.send("2");
            }, 14000);
            this.connectEvents.forEach(value => value.func(data));
        }
        this.ws.onerror = (data) => {
            this.errorEvents.forEach(value => value.func(data));
            clearInterval(this.pingInterval);
        }
        this.ws.onmessage = (message) => {
            //Si on reçoit un ping, on retourne un pong
            if (message.data == "2") {
                this.ws.send("3");
            }

            if (message.data.startsWith("42")) {
                let decoded = JSON.parse(message.data.substring(2));
                let events = this.events.filter(value => value.id == decoded[0]);
                events.forEach(event => {
                    event.func(decoded[1]);
                });
            }
        }
    }

    /**
     * listen
     */
    public on(id: string, func: (data: any) => void): { id: string, func: (data: any) => void } {
        let event = {id: id, func: func};

        switch (id) {
            case "disconnect":
                this.disconnectEvents.push(event);
                break;
            case "error":
                this.errorEvents.push(event);
                break;
            case "connect":
                this.connectEvents.push(event);
                break;
            default:
                this.events.push(event);
                break;
        }

        return event;
    }

    /**
     * emit
     */
    public emit(event: string, value: any) {
        let array = [event, value];
        let content = `42${JSON.stringify(array)}`;
        this.ws.send(content);
    }

    public async emitSync(id: string, value: any) {
        this.emit(id, value);
        return SedestralMachine.promise(async (resolve) => {
            let event = this.on(id, (data) => {
                this.events.splice(this.events.indexOf(event), 1);
                resolve(data);
            });
        });
    }

    /**
     * logic
     */
    public close() {
        this.ws.close();
    }

    public disconnect() {
        this.networkOnLogout = true;
        this.ws.close();
    }

    public removeListener(event: string) {
        this.events = this.events.filter(value => value.id != event);
    }
}