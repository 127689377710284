import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {ICommunityVisitorOutgoing} from "../../../models/community/visitor/ICommunityVisitorOutgoing";
import {ICommunityVisitorPageOutgoing} from "../../../models/community/visitor/ICommunityVisitorPageOutgoing";
import {CommunityVisitorActionType} from "../../../models/community/visitor/CommunityVisitorActionType";
import {
    SedestralMachine
} from "../../../sedestral/framework/machine/SedestralMachine";

export class CommunityVisitorsService {

    public static queuedPages: ICommunityVisitorPageOutgoing[] = [];

    public static dispose(): void {
    }

    public static init(): void {
        Network.onStarted(() => {
            Network.router.pages.stats.onNewPage((stats, page) => {
                this.queuedPages.push({
                    duration: page.duration,
                    route: page.route,
                    endTime: page.endTime,
                    startTime: page.startTime,
                    viewedPercentage: page.viewedPercentage,
                    actions: page.actions.map(value => {
                        return {
                            name: value.name,
                            value: value.value,
                            time: value.time,
                            actionType: value.type == "link" ? CommunityVisitorActionType.LINK : CommunityVisitorActionType.LINK
                        }
                    })
                });
            });

            Network.router.pages.stats.onClosePage(stats => this.save());
            SedestralMachine.setInterval(() => this.save(), 15000);
        });
    }

    public static save() {
        if (this.queuedPages.length > 0) {
            this.insert({
                frontId: Network.router.pages.stats.frontId,
                referrer: Network.router.pages.stats.referrer,
                pages: this.queuedPages
            });

            this.queuedPages = [];
        }
    }

    public static async insert(visitor: ICommunityVisitorOutgoing, component?: INetworkComponent): Promise<void> {
        await Network.postJson(ProductType.COMMUNITY, `/community/visitor`, visitor, component);
    }
}