import {Component} from "../../../../../sedestral/framework/interface/component/Component";
import * as s from "./prompt-code.scss";
import {SedestralMachine} from "../../../../../sedestral/framework/machine/SedestralMachine";
import {isValidSimpleString} from "../../../../../sedestral/framework/utilities/IsValidSimpleString";

export class PromptCodeComponent extends Component {

    public size: number;
    public inputs: Component[];

    constructor(size: number) {
        super();
        this.size = size;
        this.inputs = [];

        //language=HTML
        this.template = `
            <div class="${s.globalPromptCode}"></div>
        `;
    }

    commit() {
        for (let i = 0; i < this.size; i++) {
            let input = this.append(`<input type="text" maxlength="1" class="${s.input}"/>`);

            if (i == 0) {
                input.focus();
            }

            this.inputs.push(input);

            input.onInput((e) => {
                let value = e.target.value;
                if (value.length === 1 && isValidSimpleString(value)) {
                    e.target.value = value.toUpperCase();
                } else {
                    e.target.value = "";
                }
            });

            input.onKey((e) => {
                let nextInput = this.inputs[i + 1];
                let previousInput = this.inputs[i - 1];

                if (e.key.length === 1 && isValidSimpleString(e.key)) {

                    if (input.getValue().length >= 1) {
                        input.setValue(e.key.toUpperCase());
                    }

                    if (nextInput) {
                        SedestralMachine.requestFrame()(() => {
                            nextInput.focus();
                        });
                    }

                } else if (e.key == "Backspace") {
                    if (previousInput && input.getValue().length == 0) {
                        previousInput.setValue("");
                        previousInput.focus();
                    }
                } else if (e.key == "ArrowLeft" && previousInput) {
                    previousInput.focus();
                } else if (e.key == "ArrowRight" && nextInput) {
                    nextInput.focus();
                } else if (e.key == "Enter") {
                    this.finish();
                }
            });

            input.addListener("paste", (e) => {
                if (this.inputs.filter(value => value.isFocus()).length > 0) {
                    e.preventDefault();
                    let paste = (e.clipboardData || window["clipboardData"]).getData('text').toUpperCase();
                    let currentIndex = 0;

                    paste.split('').forEach(value => {
                        let currentInput = this.inputs[currentIndex];
                        if (currentInput) {
                            currentInput.setValue(value);
                        }

                        currentIndex++;
                    });
                }
            });
        }
        super.commit();
    }

    getValue(): string {
        return this.inputs.map(value => value.getValue()).join("");
    }

    isFinish(): boolean {
        return this.inputs.every(value => value.getValue().length > 0);
    }

    finish() {
        if (this.isFinish()) {
            this.onFinish();
        }
    }

    onFinish() {

    }

}