import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import * as s from "./tooltip.scss";

export class TooltipComponent extends Component {

    public description: string;
    public position: string;

    constructor(description: string, position?: string) {
        super();
        this.type = "SPAN";
        this.description = description;
        this.position = position;

        //language=HTML
        this.template = `
            <span class="${s.componentTooltip}"><span>?</span></span>
        `;
    }

    commit() {
        super.commit();
        if (!this.isNull()) {
            //language=HTML
            this.setTooltip(`
                <div style="max-width: 300px;text-align: center;">
                    ${this.description}
                </div>
            `, this.position);
        }
    }
}