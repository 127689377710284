import {Component} from "../../../../../sedestral/framework/interface/component/Component";
import * as s from "./loader-sliding.scss";

export class LoaderSlidingComponent extends Component {
    constructor() {
        super();
        this.template = `
            <div glSliding class="${s.globalLoaderSliding}">
                <div class="${s.bar}"></div>
            </div>
        `;
    }
}