import {OperatorType} from "../../models/enums/OperatorType";
import {
    SedestralStorage
} from "../../sedestral/framework/memory/SedestralStorage";

export class PreferencesService {
    private static _editorSpellChecker: boolean;

    static get editorSpellChecker(): boolean {
        return this._editorSpellChecker;
    }

    static set editorSpellChecker(value: boolean) {
        SedestralStorage.setItem("_editorSpellChecker", JSON.stringify(value));
        this._editorSpellChecker = value;
    }

    private static _editorLineMode: string;

    static get editorLineMode(): string {
        return this._editorLineMode;
    }

    static set editorLineMode(value: string) {
        SedestralStorage.setItem("_editorLineMode", value);
        this._editorLineMode = value;
    }

    private static _mediaVolume: number;

    static get mediaVolume(): number {
        return this._mediaVolume;
    }

    static set mediaVolume(value: number) {
        SedestralStorage.setItem("_mediaVolume", "" + value);
        this._mediaVolume = value;
    }

    private static _siteChannelPickerSelected: string;

    static get siteChannelPickerSelected(): string {
        return this._siteChannelPickerSelected;
    }

    static set siteChannelPickerSelected(value: string) {
        this._siteChannelPickerSelected = value;
        if (value == undefined) {
            SedestralStorage.removeItem("_siteChannelPickerSelected")
        } else {
            SedestralStorage.setItem("_siteChannelPickerSelected", value);
        }
    }

    private static _inboxBodyDetailsClosed: boolean;

    static get inboxBodyDetailsClosed(): boolean {
        return this._inboxBodyDetailsClosed;
    }

    static set inboxBodyDetailsClosed(value: boolean) {
        SedestralStorage.setItem("_inboxBodyDetailsClosed", JSON.stringify(value));
        this._inboxBodyDetailsClosed = value;
    }

    private static _inboxSearchValue: string;

    static get inboxSearchValue(): string {
        return this._inboxSearchValue;
    }

    static set inboxSearchValue(value: string) {
        this._inboxSearchValue = value;
        if (value == undefined) {
            SedestralStorage.removeItem("_inboxSearchValue")
        } else {
            SedestralStorage.setItem("_inboxSearchValue", value);
        }
    }

    private static _inboxSearchFilterData: string;

    static get inboxSearchFilterData(): string {
        return this._inboxSearchFilterData;
    }

    static set inboxSearchFilterData(value: string) {
        this._inboxSearchFilterData = value;
        if (value == undefined) {
            SedestralStorage.removeItem("_inboxSearchFilterData")
        } else {
            SedestralStorage.setItem("_inboxSearchFilterData", value);
        }
    }

    private static _inboxSearchSort: number;

    static get inboxSearchSort(): number {
        return this._inboxSearchSort;
    }

    static set inboxSearchSort(value: number) {
        this._inboxSearchSort = value;
        if (value == undefined) {
            SedestralStorage.removeItem("_inboxSearchSort")
        } else {
            SedestralStorage.setItem("_inboxSearchSort", "" + value);
        }
    }

    private static _inboxFilterEntryId: string;

    static get inboxFilterEntryId(): string {
        return this._inboxFilterEntryId;
    }

    static set inboxFilterEntryId(value: string) {
        this._inboxFilterEntryId = value;
        SedestralStorage.setItem("_inboxFilterEntryId", value);
    }

    private static _inboxFilterEntryData: string;

    static get inboxFilterEntryData(): string {
        return this._inboxFilterEntryData;
    }

    static set inboxFilterEntryData(value: string) {
        this._inboxFilterEntryData = value;
        SedestralStorage.setItem("_inboxFilterEntryData", value);
    }

    private static _inboxFilterEntryName: string;

    static get inboxFilterEntryName(): string {
        return this._inboxFilterEntryName;
    }

    static set inboxFilterEntryName(value: string) {
        this._inboxFilterEntryName = value;
        SedestralStorage.setItem("_inboxFilterEntryName", value);
    }

    private static _inboxFilterId: string;

    static get inboxFilterId(): string {
        return this._inboxFilterId;
    }

    static set inboxFilterId(value: string) {
        this._inboxFilterId = value;
        SedestralStorage.setItem("_inboxFilterId", value);
    }

    private static _inboxFilterData: string;

    static get inboxFilterData(): string {
        return this._inboxFilterData;
    }

    static set inboxFilterData(value: string) {
        this._inboxFilterData = value;
        SedestralStorage.setItem("_inboxFilterData", value);
    }

    private static _inboxFilterName: string;

    static get inboxFilterName(): string {
        return this._inboxFilterName;
    }

    static set inboxFilterName(value: string) {
        this._inboxFilterName = value;
        SedestralStorage.setItem("_inboxFilterName", value);
    }

    private static _contactDetailsSort: string;

    static get contactDetailsSort(): string {
        return this._contactDetailsSort;
    }

    static set contactDetailsSort(value: string) {
        SedestralStorage.setItem("_contactDetailsSort", value);
        this._contactDetailsSort = value;
    }

    private static _statisticsInboxHomeDays: number;

    static get statisticsInboxHomeDays(): number {
        return this._statisticsInboxHomeDays;
    }

    static set statisticsInboxHomeDays(value: number) {
        SedestralStorage.setItem("_statisticsInboxHomeDays", "" + value);
        this._statisticsInboxHomeDays = value;
    }

    private static _statisticsInboxConversationFilters: string;

    static get statisticsInboxConversationFilters(): string {
        return this._statisticsInboxConversationFilters == "undefined" || this._statisticsInboxConversationFilters == "null" ? undefined : this._statisticsInboxConversationFilters;
    }

    static set statisticsInboxConversationFilters(value: string) {
        SedestralStorage.setItem("_statisticsInboxConversationCountFilters", value);
        this._statisticsInboxConversationFilters = value;
    }

    private static _statisticsInboxMessagesFilters: string;

    static get statisticsInboxMessagesFilters(): string {
        return this._statisticsInboxMessagesFilters == "undefined" || this._statisticsInboxMessagesFilters == "null" ? undefined : this._statisticsInboxMessagesFilters;
    }

    static set statisticsInboxMessagesFilters(value: string) {
        SedestralStorage.setItem("_statisticsInboxMessagesFilters", value);
        this._statisticsInboxMessagesFilters = value;
    }

    private static _statisticsInboxMembersSort: string;

    static get statisticsInboxMembersSort(): string {
        return this._statisticsInboxMembersSort == "undefined" || this._statisticsInboxMembersSort == "null" ? undefined : this._statisticsInboxMembersSort;
    }

    static set statisticsInboxMembersSort(value: string) {
        SedestralStorage.setItem("_statisticsInboxMembersSort", value);
        this._statisticsInboxMembersSort = value;
    }

    private static _statisticsInboxSplitType: number;

    static get statisticsInboxSplitType(): number {
        return this._statisticsInboxSplitType;
    }

    static set statisticsInboxSplitType(value: number) {
        SedestralStorage.setItem("_statisticsInboxConversationCountSplitType", "" + value);
        this._statisticsInboxSplitType = value;
    }

    private static _statisticsInboxFirstInterval: string;

    static get statisticsInboxFirstInterval(): string {
        return this._statisticsInboxFirstInterval;
    }

    static set statisticsInboxFirstInterval(value: string) {
        SedestralStorage.setItem("_statisticsInboxConversationCountFirstInterval", value);
        this._statisticsInboxFirstInterval = value;
    }

    private static _statisticsInboxSecondInterval: string;

    static get statisticsInboxSecondInterval(): string {
        return this._statisticsInboxSecondInterval;
    }

    static set statisticsInboxSecondInterval(value: string) {
        SedestralStorage.setItem("_statisticsInboxConversationCountSecondInterval", value);
        this._statisticsInboxSecondInterval = value;
    }

    private static _timeZoneOffset: number;

    static get timeZoneOffset(): number {
        return this._timeZoneOffset;
    }

    static set timeZoneOffset(value: number) {
        SedestralStorage.setItem("_timeZoneOffset", "" + value);
        this._timeZoneOffset = value;
    }

    private static _timeZoneName: string;

    static get timeZoneName(): string {
        return this._timeZoneName;
    }

    static set timeZoneName(value: string) {
        SedestralStorage.setItem("_timeZoneName", "" + value);
        this._timeZoneName = value;
    }

    private static _hour12: boolean;

    static get hour12(): boolean {
        return this._hour12;
    }

    static set hour12(value: boolean) {
        SedestralStorage.setItem("_hour12", JSON.stringify(value));
        this._hour12 = value;
    }

    private static _livechatPopupCloseTime: number;

    static get livechatPopupCloseTime(): number {
        return this._livechatPopupCloseTime;
    }

    static set livechatPopupCloseTime(value: number) {
        SedestralStorage.setItem("_livechatPopupCloseTime", "" + value);
        this._livechatPopupCloseTime = value;
    }

    public static init(): void {
        this._editorSpellChecker = JSON.parse(SedestralStorage.getItem("_editorSpellChecker"));
        this._editorLineMode = SedestralStorage.getItem("_editorLineMode");
        this._contactDetailsSort = SedestralStorage.getItem("_contactDetailsSort");
        this._mediaVolume = SedestralStorage.getItem("_mediaVolume") == undefined ? 0.5 : parseFloat(SedestralStorage.getItem("_mediaVolume"));
        this._livechatPopupCloseTime = SedestralStorage.getItem("_livechatPopupCloseTime") == undefined ? 0 : Number(SedestralStorage.getItem("_livechatPopupCloseTime"));
        this._inboxFilterName = SedestralStorage.getItem("_inboxFilterName") == undefined ? "Inbox" : SedestralStorage.getItem("_inboxFilterName");
        this._inboxFilterEntryName = SedestralStorage.getItem("_inboxFilterEntryName") == undefined ? "Général" : SedestralStorage.getItem("_inboxFilterEntryName");
        this._inboxFilterEntryData = SedestralStorage.getItem("_inboxFilterEntryData") == undefined ? "inbox" : SedestralStorage.getItem("_inboxFilterEntryData");
        this._inboxFilterEntryId = SedestralStorage.getItem("_inboxFilterEntryId") == undefined ? "inbox" : SedestralStorage.getItem("_inboxFilterEntryId");
        this._inboxSearchValue = SedestralStorage.getItem("_inboxSearchValue");
        this._siteChannelPickerSelected = SedestralStorage.getItem("_siteChannelPickerSelected");
        this._inboxSearchSort = SedestralStorage.getItem("_inboxSearchSort") == undefined ? OperatorType.ORDER_DESC : parseInt(SedestralStorage.getItem("_inboxSearchSort"));
        this._inboxSearchFilterData = SedestralStorage.getItem("_inboxSearchFilterData");
        this._inboxFilterId = SedestralStorage.getItem("_inboxFilterId") == undefined ? "inbox" : SedestralStorage.getItem("_inboxFilterId");
        this._inboxFilterData = SedestralStorage.getItem("_inboxFilterData") == undefined ? "inbox" : SedestralStorage.getItem("_inboxFilterData");
        this._inboxBodyDetailsClosed = JSON.parse(SedestralStorage.getItem("_inboxBodyDetailsClosed"));
        this._statisticsInboxHomeDays = SedestralStorage.getItem("_statisticsInboxHomeDays") == undefined ? 7 : parseInt(SedestralStorage.getItem("_statisticsInboxHomeDays"));
        this._statisticsInboxConversationFilters = SedestralStorage.getItem("_statisticsInboxConversationCountFilters");
        this._statisticsInboxMessagesFilters = SedestralStorage.getItem("_statisticsInboxMessagesFilters");
        this._statisticsInboxMembersSort = SedestralStorage.getItem("_statisticsInboxMembersSort");
        this._statisticsInboxSplitType = SedestralStorage.getItem("_statisticsInboxConversationCountSplitType") == undefined ? -1 : parseInt(SedestralStorage.getItem("_statisticsInboxConversationCountSplitType"));
        this._statisticsInboxFirstInterval = SedestralStorage.getItem("_statisticsInboxConversationCountFirstInterval");
        this._statisticsInboxSecondInterval = SedestralStorage.getItem("_statisticsInboxConversationCountSecondInterval");
        this._timeZoneOffset = new Date().getTimezoneOffset();
        this._timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this._hour12 = new Intl.DateTimeFormat().resolvedOptions().hour12;
    }

    public static dispose(): void {

    }
}