import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral/framework/SedestralLogic";
import {Network} from "./network/Network";
import {Panel} from "./products/panel/Panel";
import {PanelNetwork} from "./products/panel/network/PanelNetwork";
import {config} from "./config";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import Hotjar from '@hotjar/browser';
import {EntityService} from "./services/entity/EntityService";
import {PermissionsService} from "./services/permissions/PermissionsService";
import {Resources} from "./resources/Resources";
import {loadScript} from "./sedestral/framework/utilities/Script";

config.import(ProductName.toString(ProductType.PANEL));
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await PanelNetwork.init();
    await Network.init(ProductName.toString(ProductType.PANEL));
    await Panel.init();

    try {
        if (!config.isDev && (!EntityService.activeSite || !PermissionsService.canProduct(ProductType.INBOX))) {
            const hotjarPanelId = config.hotjarPanelId;
            const hotjarVersion = 6;
            Hotjar.init(Number(hotjarPanelId), hotjarVersion);
        }
    } catch (e) {
        console.error(e);
    }

    try {
        loadScript("https://js-na1.hs-scripts.com/48111012.js", () => {});
    } catch (e) {
        console.error(e);
    }
})
;