import {Component} from "../../../../../sedestral/framework/interface/component/Component";
import {ICheckboxSliding} from "./types/ICheckboxSliding";
import * as s from "./checkbox-sliding.scss";
import {SedestralMachine} from "../../../../../sedestral/framework/machine/SedestralMachine";

export class CheckboxSlidingComponent extends Component {

    private entries: ICheckboxSliding[];
    private selectorComponent: Component;
    private currentComponent: Component;
    private selected: string;
    public currentValue: string;

    constructor(entries: ICheckboxSliding[], selected?: string) {
        super();
        this.entries = entries;
        this.selected = selected;

        //language=HTML
        this.template = `
            <div chSliding class="${s.globalCheckboxSliding}">
                <div chEntry class="${s.checkboxEntry} ${s.selector}"></div>
            </div>
        `;
    }


    commit() {
        this.selectorComponent = this.el(s.selector);
        for (let entry of this.entries) {
            let entryComponent = this.append(`
                <div chEntry ${entry.title == undefined ? `` : `data-tooltip="${entry.title}"`} class="${s.checkboxEntry}">
                    ${entry.html}
                </div>
            `);
            entryComponent.onClick(() => {
                this.select(entry.value);
            });
            entry.component = entryComponent;
        }

        this.currentValue = this.entries[0].value;

        if (this.selected != undefined) {
            SedestralMachine.requestFrame()(() => this.select(this.selected));
        }

        super.commitTooltips();
        super.commit();
    }

    onSelect(value: string) {

    }

    select(value: string, noAnimation?: boolean) {
        for (let entry of this.entries) {
            if (entry.value == value) {
                let speed = noAnimation != undefined ? 10 : 200;
                this.selectorComponent.translate({moveLeft: entry.component.getParentOffsets().left, delay: speed});
                this.currentComponent = entry.component;
                this.currentValue = entry.value;
                this.onSelect(value);
            }
        }
    }
}