import {Component} from "../../../../../sedestral/framework/interface/component/Component";

export class ButtonCheckComponent extends Component {
    public checked: boolean;

    constructor(checked: boolean) {
        super();
        this.checked = checked;
    }

    commit() {
        this.bind();
        super.commit();
    }

    bind() {
        this.onClick(() => {
            this.stateChange(!this.checked);
        }, true);
    }

    /**
     * state
     */
    stateChange(checked: boolean, noCallback?: boolean) {
        this.checked = checked;
        this.stateChangeClass();

        if (!noCallback) {
            if (checked) {
                this.onCheck();
            } else {
                this.onUnCheck();
            }

            this.onStateChange(checked);
        }
    }

    stateChangeClass() {
        this.removeAttribute("checked");
        this.removeAttribute("unchecked");
        this.setAttribute(this.state(), "");
    }

    state(): string {
        return this.checked ? "checked" : "unchecked";
    }

    /**
     * override
     */

    onCheck() {

    }

    onUnCheck() {

    }

    onStateChange(checked: boolean) {

    }

}