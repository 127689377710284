import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {ICommunityCategoryModel} from "../../../models/community/category/ICommunityCategoryModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ProductType} from "../../../models/product/ProductType";
import {CommunityFilesService} from "../files/CommunityFilesService";
import {SedestralSsr} from "../../../sedestral/framework/ssr/SedestralSsr";
import {config} from "../../../config";
import {ProductName} from "../../../models/product/ProductName";
import {WebSocketCommunityEventName} from "../../../network/socket/names/WebSocketCommunityEventName";

export class CommunityCategoryService {
    public static categories: ICommunityCategoryModel[] = observable([]);
    public static createEvents: ((category: ICommunityCategoryModel) => void)[] = [];
    public static deleteEvents: ((category: ICommunityCategoryModel) => void)[] = [];
    public static updateEvents: ((category: ICommunityCategoryModel) => void)[] = [];

    public static dispose(): void {
        this.createEvents = [];
        this.deleteEvents = [];
        this.updateEvents = [];
    }

    public static init(): void {
        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["CommunityCategoryService"] = this;
        }

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_CREATE, (data) => {
            this.createEvents.forEach(value => value(this.store(data)))
        });
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_UPDATE, (data) => {
            this.updateEvents.forEach(value => value(this.store(data)))
        });
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CATEGORY_DELETE, (data) => {
            this.deleteEvents.forEach(value => value(this.store(data)))
        });
    }

    public static async findCategories(communityId: string, language: string, component?: INetworkComponent): Promise<ICommunityCategoryModel[]> {
        if (SedestralSsr.hasSsr() && this.categories) {
            return Promise.resolve(this.categories);
        }

        let request = await Network.get(ProductType.COMMUNITY, `/community/categories/${communityId}/${language}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static storeAll(categories: ICommunityCategoryModel[]): ICommunityCategoryModel[] {
        for (let key in categories) {
            categories[key] = this.store(categories[key]);
        }
        return Services.storeAll(categories);
    }

    public static store(category: ICommunityCategoryModel): ICommunityCategoryModel {
        if (category.imageFile) {
            category.imageFile = CommunityFilesService.store(category.imageFile);
        }

        return Services.store("id", this.categories, category);
    }

    public static unStore(category: ICommunityCategoryModel): void {
        Services.unStore("id", this.categories, category);
    }
}