import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./offer-template-icon.scss";
import {OfferProductTemplateType} from "../../../../../../../models/offer/product/templates/OfferProductTemplateType";

export class OfferTemplateIconComponent extends Component {
    constructor(type: OfferProductTemplateType) {
        super();

        let cl = s.basic;

        switch (type) {
            case OfferProductTemplateType.BLOG_BASIC:
                cl = s.basic;
                break;
            case OfferProductTemplateType.BLOG_PRO:
                cl = s.medium;
                break;
            case OfferProductTemplateType.BLOG_ENTERPRISE:
                cl = s.pro;
                break;
        }

        //language=HTML
        this.template = `
            <div class="${s.componentOfferTemplateIcon} ${cl}"></div>
        `;
    }
}