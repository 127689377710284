import {SedestralPageRouter} from "../pages/SedestralPageRouter";
import {SedestralStorage} from "../../framework/memory/SedestralStorage";
import {SedestralMachine} from "../../framework/machine/SedestralMachine";
import {SedestralInterface} from "../../framework/interface/SedestralInterface";
import {MachineListener} from "../../framework/machine/listener/MachineListener";
import {ISedestralPageStat} from "./types/ISedestralPageStat";
import {SedestralPageStatsActivity} from "./activity/SedestralPageStatsActivity";
import {randomUUID} from "../../framework/utilities/RandomUUID";

export class SedestralPageStats {

    public pages: SedestralPageRouter;

    public frontId: string;
    public referrer: string;
    public baseUrl: string;

    public currentPage: ISedestralPageStat;
    public currentPageScroll: MachineListener;
    public currentPageLink: MachineListener;

    public newPageFunc: ((stats: SedestralPageStats, page: ISedestralPageStat) => void)[] = [];
    public closePageFunc: ((stats: SedestralPageStats) => void)[] = [];

    public activity: SedestralPageStatsActivity;

    constructor(pages: SedestralPageRouter) {
        this.pages = pages;

        let frontId = SedestralStorage.getItem("fid");
        if (frontId == null) {
            frontId = randomUUID().replaceAll("-", "");
            SedestralStorage.setItem("fid", frontId);
        }

        this.frontId = frontId;
        this.baseUrl = window.location.origin;
        this.referrer = document.referrer.startsWith(this.baseUrl) ? undefined : document.referrer;

        this.activity = new SedestralPageStatsActivity();

        this.pages.onPopState(undefined, () => {
            this.endPage();
            this.startPage();
        });

        SedestralMachine.addListener(window, "beforeunload", () => this.close());
    }

    startPage() {
        this.currentPage = {
            duration: 0,
            endTime: 0,
            startTime: Date.now(),
            route: this.pages.getPath(),
            viewedPercentage: 0,
            actions: []
        };

        //duration
        this.activity.init();

        //scroll
        this.currentPageScroll = SedestralInterface.main.onScroll(() => {
            let percentage = SedestralInterface.main.getScrollPercent();
            if (percentage > this.currentPage.viewedPercentage) {
                this.currentPage.viewedPercentage = percentage;
            }
        });

        //link
        this.currentPageLink = SedestralInterface.main.putListener(window, "click", (e) => {
            let target = e.target;
            while (target != null && target.nodeName !== 'A') {
                target = target.parentNode;
            }
            if (target && target.nodeName === 'A') {
                if (!target.href.startsWith(this.baseUrl)) {
                    if (this.currentPage) {
                        this.currentPage.actions.push({
                            name: target.innerText,
                            value: target.href,
                            time: Date.now(),
                            type: "link"
                        });
                    }
                }
            }
        });
    }

    endPage() {
        if (this.currentPage) {
            this.currentPage.endTime = Date.now();
            this.currentPage.duration = this.activity.currentPageDuration;
            this.activity.dispose();

            SedestralMachine.removeListener(this.currentPageScroll);
            SedestralMachine.removeListener(this.currentPageLink);

            this.newPageFunc.forEach(value => value(this, this.currentPage));
        }
    }

    close() {
        this.endPage();
        this.closePageFunc.forEach(value => value(this));
    }

    onNewPage(func: (stats: SedestralPageStats, page: ISedestralPageStat) => void) {
        this.newPageFunc.push(func);
    }

    onClosePage(func: (stats: SedestralPageStats) => void) {
        this.closePageFunc.push(func);
    }

}