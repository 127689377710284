export class config {
    public static websiteUrl: string = process.env.WEBSITE_URL;
    public static domain: string = process.env.APP_URL ?? window.location.origin;
    public static version: string = process.env.APP_VERSION;
    public static environment: string = process.env.APP_ENVIRONMENT;
    public static isDev: boolean = process.env.APP_ENVIRONMENT === "dev";
    public static isProd: boolean = config.environment == "prod";
    public static httpPanelUrl: string = process.env.SPRING_HTTP_PANEL;
    public static httpCommunityUrl: string = process.env.SPRING_HTTP_COMMUNITY;
    public static websocketPanelUrl: string = process.env.SPRING_WS_PANEL;
    public static websocketCommunityUrl: string = process.env.SPRING_WS_COMMUNITY;
    public static editorCollaborationUrl: string = process.env.EDITOR_COLLABORATION_WS;
    public static rumEnabled: boolean = process.env.RUM_ENABLED === "true";
    public static blogSubDomain: string = process.env.BLOG_SUBDOMAIN;

    public static product: string;

    public static tenorKey: string = "1HFPKNKBSCVT";
    public static tenorHost: string = "https://g.tenor.com/v1/";
    public static tenorMediaHost: string = "https://media.tenor.com/";
    public static domainParent: string = "sedestral.com";

    public static prestaShopModuleVersion: string = "1.0.14";
    public static prestaShopFileName: string = "sedestral_v" + config.prestaShopModuleVersion + ".zip";
    public static prestaShopModuleLink: string =  "/module/" + config.prestaShopFileName;

    public static hotjarId: string = process.env.HOTJAR_ID;
    public static hotjarPanelId: string = process.env.HOTJAR_PANEL_ID;

    static import(product: string) {
        this.product = product;
    }
}