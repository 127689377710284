import {Network} from "../../../network/Network";
import {AccountSessionService} from "../../../services/account/session/AccountSessionService";
import {SiteService} from "../../../services/site/SiteService";
import {SiteChannelService} from "../../../services/site/channel/SiteChannelService";
import {AccountService} from "../../../services/account/AccountService";
import {AvatarService} from "../../../services/avatar/AvatarService";
import {AvatarColorService} from "../../../services/avatar/color/AvatarColorService";
import {FileService} from "../../../services/file/FileService";
import {EntityService} from "../../../services/entity/EntityService";
import {InvitationService} from "../../../services/invitation/InvitationService";
import {
    SedestralInterface
} from "../../../sedestral/framework/interface/SedestralInterface";
import {
    EmptyNetworkComponent
} from "../../components/global/empty/network/EmptyNetworkComponent";
import {WebPushService} from "../../../services/webpush/WebPushService";
import {PreferencesService} from "../../../services/preferences/PreferencesService";
import {StatisticsService} from "../../../services/statistics/StatisticsService";
import {AccountPreferencesService} from "../../../services/account/preferences/AccountPreferencesService";
import {RoleService} from "../../../services/roles/RoleService";
import {RoleAccountService} from "../../../services/roles/account/RoleAccountService";
import {PermissionsService} from "../../../services/permissions/PermissionsService";
import {CollaborationEditorService} from "../../../services/collaboration/editor/CollaborationEditorService";
import {AccountPasswordLostService} from "../../../services/account/password/lost/AccountPasswordLostService";
import {SedestralSsr} from "../../../sedestral/framework/ssr/SedestralSsr";
import {GPTService} from "../../../services/gpt/GPTService";
import {ProductType} from "../../../models/product/ProductType";
import {CommunityService} from "../../../services/community/CommunityService";
import {OfferBlockedModalComponent} from "../components/components/offer/blocked/modal/OfferBlockedModalComponent";
import {AccountLogService} from "../../../services/account/log/AccountLogService";
import {TrackingLogService} from "../../../services/tracking/TrackingLogService";

export class PanelNetwork {

    static async init() {
        /**
         * override
         */
        Network.onToken = async (): Promise<boolean> => {
            return SedestralSsr.hasSsr() ? true : await AccountSessionService.token();
        }

        Network.onLogin = async (): Promise<boolean> => {
            Network.logged = true;

            let connected = await Network.startWebSocket(ProductType.PANEL);
            if (connected) {
                await AccountSessionService.onLogin();
                this.initServices();
                this.initEvents();
                return true;
            }

            return false;
        }

        Network.onLogout = async (): Promise<boolean> => {
            Network.logged = false;
            AccountSessionService.onLogout();
            this.disposeServices();
            await Network.disconnect(ProductType.PANEL, true);
            return true;
        }

        Network.onError = (message) => {
            if (Network.router.static?.components.notifications) {
                Network.router.static?.components.notifications.notify(message, undefined, 8000);
            }
        }

        Network.onPaymentRequired = (currentQuantity, limitQuantity, solutionType, productType) => {
            let modal = new OfferBlockedModalComponent(currentQuantity, limitQuantity, solutionType, productType);
            modal.screenRender();
        }

        /**
         * on error
         */

        Network.lostFunctions = [];
        Network.onNetworkLost(() => {
            SedestralInterface.body.render(new EmptyNetworkComponent());
        });

        Network.lostSolvedFunctions = [];
        Network.onNetworkLostSolved(() => window.location.reload());
    }

    static initServices() {
        EntityService.init();
        SiteService.init();
        SiteChannelService.init();

        RoleService.init();
        RoleAccountService.init();

        AccountPasswordLostService.init();
        AccountPreferencesService.init();
        AccountService.init();
        AccountLogService.init();
        TrackingLogService.init();

        CollaborationEditorService.init();


        InvitationService.init();

        AvatarService.init();
        AvatarColorService.init();

        FileService.init();

        WebPushService.init();


        PreferencesService.init();

        StatisticsService.init();

        PermissionsService.init();

        GPTService.init();

        CommunityService.init();
    }

    static disposeServices() {
        SiteService.dispose();
        SiteChannelService.dispose();

        AccountPasswordLostService.dispose();
        AccountPreferencesService.dispose();
        AccountSessionService.dispose();
        AccountService.dispose();
        AccountLogService.dispose();
        TrackingLogService.dispose();

        InvitationService.dispose();

        AvatarService.dispose();
        AvatarColorService.dispose();

        FileService.dispose();

        EntityService.dispose();

        WebPushService.dispose();

        PreferencesService.dispose();

        StatisticsService.dispose();

        PermissionsService.dispose();

        GPTService.dispose();

        CommunityService.dispose();
    }

    static initEvents() {
        SiteService.onReload = () => {
            location.reload();
        }
    }
}