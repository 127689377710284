import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./offer-price.scss";
import {IOfferPeriodTypeModel} from "../../../../../../models/offer/IOfferPeriodTypeModel";
import {OfferPeriodType} from "../../../../../../models/offer/product/OfferPeriodType";
import {Resources} from "../../../../../../resources/Resources";
import {
    LoaderLightComponent
} from "../../../../../components/global/loader/light/LoaderLightComponent";
import {IOfferPrice} from "./types/IOfferPrice";
import {
    numberPrice
} from "../../../../../../sedestral/framework/utilities/NumberPrice";

export class OfferPriceComponent extends Component {

    public period: IOfferPeriodTypeModel;
    public price: number;

    public button: Component;
    public loader: LoaderLightComponent;

    constructor(p: IOfferPrice) {
        super();
        this.period = p.period;
        this.price = p.price;
        if (!p.noCalculateYear && p.period.periodType == OfferPeriodType.ANNUAL) {
            this.price = (p.price - (p.price * p.period.advantagePercentage)) * 12;
        }

        //language=HTML
        this.template = `
            <div class="${s.componentOfferPrice}">
                <div class="${s.names}">
                    <div class="${s.title}">
                        ${Resources.t("words.offerTotalPrice")}
                    </div>
                    <div class="${s.pricing}">
                        <span class="${s.price}">${numberPrice(this.price)}
                            ${!p.totalPrice ? Resources.t("words.offerExcludeTax") : Resources.t("words.offerIncludeTax")}</span>

                        ${p.totalPrice ? `` : `/<span
                            class="${s.period}">${Resources.t(`words.offerPeriodTitle${this.period.periodType}`)}</span>`}
                    </div>
                </div>
                <div class="${s.button}">
                    ${p.text}
                </div>
            </div>
        `;
    }

    commit() {
        this.button = this.el(s.button);
        this.button.onClick(() => {
            if (!this.button.hasClass(s.loading))
                this.onGetStarted()
        });

        super.commit();
    }

    setLoading() {
        this.remLoading();

        this.loader = new LoaderLightComponent({strokeColor: "white"});

        this.button.addClass(s.loading);
        this.button.render(this.loader);
    }

    remLoading() {
        if (this.loader) {
            this.loader.remove();
            this.loader = undefined;
        }

        this.button.removeClass(s.loading);
    }

    onGetStarted() {
        this.setLoading();
    }
}