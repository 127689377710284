import {RoleService} from "../roles/RoleService";
import {RolePermissionsType} from "../../models/roles/types/RolePermissionsType";
import {EntityService} from "../entity/EntityService";
import {ProductType} from "../../models/product/ProductType";

export class PermissionsService {
    static init() {

    }

    static dispose() {

    }

    static admin(): boolean {
        return RoleService.can(RolePermissionsType.ADMINISTRATOR);
    }

    static blogWriting(): boolean {
        return RoleService.can(
            RolePermissionsType.BLOG_WRITING,
            RolePermissionsType.BLOG_ADMINISTRATOR,
            RolePermissionsType.BLOG_PUBLISH
        );
    }

    static blogLoad(): boolean {
        return RoleService.can(
            RolePermissionsType.BLOG_READING,
            RolePermissionsType.BLOG_WRITING,
            RolePermissionsType.BLOG_ADMINISTRATOR,
            RolePermissionsType.BLOG_PUBLISH
        );
    }

    static helpcenterWriting(): boolean {
        return RoleService.can(
            RolePermissionsType.HELPCENTER_WRITING,
            RolePermissionsType.HELPCENTER_ADMINISTRATOR,
            RolePermissionsType.HELPCENTER_PUBLISH
        );
    }

    /**
     * product
     */

    static canProduct(type: ProductType): boolean {
        if (!EntityService.activeSite) {
            return false;
        }
        return EntityService.activeSite.products.includes(type);
    }

    /**
     * utilities
     */
    static d(permission: boolean): string {
        return permission ? `` : `display:none;`;
    }

    static o(permission: boolean): string {
        return permission ? `` : `opacity:0.3;pointer-events:none;`;
    }

}