import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import {ProductType} from "../../../../../../models/product/ProductType";
import {ProductName} from "../../../../../../models/product/ProductName";
import * as s from "./capsule-icon.scss";

export class CapsuleIconComponent extends Component {
    constructor(product: ProductType) {
        super();

        //language=HTML
        this.template = `
            <div class="${s.componentCapsuleIcon} ${s[ProductName.toString(product)]}"></div>
        `;
    }
}