export class WebSocketPanelEventName {
    public static CONNECTION_READY = "connection/ready";
    public static CONNECTION_ERROR = "connection/error";


    //collab editor room
    public static COLLABORATION_EDITOR_JOIN = "collaboration/editor/join"; //compose editor
    public static COLLABORATION_EDITOR_LEAVE = "collaboration/editor/leave"; //compose editor
    public static COLLABORATION_EDITOR_SAVE_ATTACHMENTS = "collaboration/editor/attachments"; //compose editor
    public static COLLABORATION_EDITOR_SAVE_METADATA = "collaboration/editor/metadata"; //compose editor
    public static COLLABORATION_EDITOR_VIEWER_JOIN = "collaboration/editor/viewer/join"; //compose editor
    public static COLLABORATION_EDITOR_VIEWER_LEAVE = "collaboration/editor/viewer/leave"; //compose editor

    //manage room
    public static SITE_JOIN = "site/join"; // receive join
    public static SITE_LEAVE = "site/leave"; // receive leave
    //site room
    public static SITE_UPDATE = "site/update"; //compose site
    //site and livechat room
    public static SITE_JOIN_ACCOUNT = "site/join/account"; //compose joined account
    public static SITE_LEAVE_ACCOUNT = "site/leave/account"; //compose account
    //inbox room
    //no room
    public static SITE_RELOAD = "site/reload"; //compose true

    //site room
    public static ROLE_UPDATE = "role/update"; //compose role
    //set to roles room
    public static ROLE_CREATE = "role/create"; //compose role
    public static ROLE_DELETE = "role/delete"; //compose role

    //no room
    public static ACCOUNT_LOGOUT = "account/logout";//compose true
    public static ACCOUNT_UPDATE = "account/update"; //compose account
    //site room
    public static ACCOUNT_KICK = "account/kick"; //compose account

    // inbox and livechat room
    public static ENTITY_TYPING = "entity/typing"; //compose entity typing
    //site room
    public static ENTITY_UPDATE = "entity/update"; //compose contact or account

    //site channel
    public static SITE_CHANNEL_UPDATE = "sitechannel/update";
    //gpt service
    public static GPT_SEGMENT = "gpt/segment"; // receive
}