import {Component} from "../../../../../sedestral/framework/interface/component/Component";
import * as s from "./text-resizeable.scss";

export class TextResizeableComponent extends Component {

    constructor(text: string, fill: string) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.globalTextResizeable}" style="color:${fill};">
                ${text}
            </div>
        `;
    }

    commit() {
        this.onVisible(() => this.setStyle(`font-size: ${this.getWidth() / 2.5}px;`));
        super.commit();
    }

}