import {ISiteModel} from "../../models/site/ISiteModel";
import {Network} from "../../network/Network";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {AvatarService} from "../avatar/AvatarService";
import {AccountService} from "../account/AccountService";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {HttpStatus} from "../../network/status/HttpStatus";
import {IAccountModel} from "../../models/account/IAccountModel";
import {ProductType} from "../../models/product/ProductType";
import {ISiteColumnsModel} from "../../models/site/columns/ISiteColumnsModel";
import {ISiteColumnsColumnModel} from "../../models/site/columns/ISiteColumnsColumnModel";
import {ErrorCode} from "../../network/status/error/ErrorCode";
import {Resources} from "../../resources/Resources";
import {ISiteAddressModel} from "../../models/site/address/ISiteAddressModel";
import {ISiteTaxModel} from "../../models/site/address/tax/ITaxTypeModel";
import {EntityService} from "../entity/EntityService";
import {getPaymentProviderById} from "../../models/payment/PaymentProviderType";

export class SiteService {
    public static sites: ISiteModel[] = observable([]);

    public static dispose(): void {
        this.sites = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.SITE_JOIN_ACCOUNT, (data) => AccountService.store(data));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.SITE_LEAVE_ACCOUNT, (data) => {
            let account = AccountService.store(data);
            account.siteId = undefined;
            account.active = false;
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.SITE_UPDATE, (data) => this.store(data));
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.SITE_RELOAD, () => this.onReload());
    }

    /**
     * http
     */

    public static async findActiveAccounts(component?: INetworkComponent): Promise<IAccountModel[]> {
        let request = await Network.get(ProductType.PANEL, `/site/activeAccounts`, component);
        if (request.status == HttpStatus.OK) {
            request.data = AccountService.storeAll(request.data);
            return request.data;
        }

        return undefined;
    }

    public static async findMind(component?: INetworkComponent): Promise<ISiteModel[]> {
        let request = await Network.get(ProductType.PANEL, `/sites/mind`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async findStatistics(component?: INetworkComponent): Promise<any> {
        let request = await Network.get(ProductType.PANEL, `/sites/statistics`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async select(siteId: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/sites/select",
            {
                siteId: siteId
            }, component);

        return request.status == HttpStatus.OK;
    }

    public static async unSelect(siteId: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.PANEL, "/sites/unselect",
            {
                siteId: siteId
            }, component);

        return request.status == HttpStatus.OK;
    }

    public static async create(name: string, component?: INetworkComponent): Promise<ISiteModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.SITE_COUNT_MAX, message: Resources.t("words.maxSite")}
        ]);

        let request = await Network.post(ProductType.PANEL, "/sites",
            {
                name: name,
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async updateAvatar(files: File[], component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.postFormData(ProductType.PANEL, `/sites/avatar/update`, {
            files: files
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async update(name: string, link: string, component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.post(ProductType.PANEL, "/sites/update",
            {
                name: name,
                link: link
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async updateAddress(address: ISiteAddressModel, component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.postJson(ProductType.PANEL, "/sites/update/address",
            address, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async orderColumns(columns: ISiteColumnsModel, component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.postJson(ProductType.PANEL, `/sites/column/order`, columns, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async createColumn(siteColumn: ISiteColumnsColumnModel, component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.postJson(ProductType.PANEL, `/sites/column`, siteColumn, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async deleteColumn(id: string, component?: INetworkComponent): Promise<ISiteModel> {
        let request = await Network.delete(ProductType.PANEL, `/sites/column/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async getTaxTypes(component?: INetworkComponent): Promise<ISiteTaxModel[]> {
        let request = await Network.get(ProductType.PANEL, `/sites/taxTypes`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    /**
     * websocket
     */
    public static async join(id: string) {
        if (id != null) {
            await Network.join(ProductType.PANEL, id, WebSocketPanelEventName.SITE_JOIN);
        }
    }

    public static leave(id: string) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.SITE_LEAVE, id);
    }

    /**
     * override
     */

    public static onReload() {

    }

    /**
     * store
     */

    public static storeAll(conversations: ISiteModel[]): ISiteModel[] {
        for (let key in conversations)
            conversations[key] = this.store(conversations[key]);

        return Services.storeAll(conversations);
    }

    public static store(site?: ISiteModel): ISiteModel {
        if (site != undefined) {
            if (EntityService.activeSite && EntityService.activeSite.id == site.id) {
                EntityService.activeSite = site;
            }

            site.avatar = AvatarService.store(site.avatar);
            site.paymentProvider = getPaymentProviderById(site.paymentProviderType);

            return Services.store("id", this.sites, site);
        }
    }
}