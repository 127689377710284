import {
    CheckboxSlidingComponent
} from "../../../../../components/global/checkbox/sliding/CheckboxSlidingComponent";
import {IOfferPeriodTypeModel} from "../../../../../../models/offer/IOfferPeriodTypeModel";
import {Resources} from "../../../../../../resources/Resources";

export class OfferPeriodComponent extends CheckboxSlidingComponent {
    constructor(periods: IOfferPeriodTypeModel[]) {
        super(periods.map(period => {
            return {html: Resources.t(`words.offerPeriod${period.periodType}`), value: period.periodType + ""}
        }));
    }
}