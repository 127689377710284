import {NetworkSocketConnection} from "./NetworkSocketConnection";
import {INetworkSocket} from "./types/INetworkSocket";
import {WebSocketPanelEventName} from "./names/WebSocketPanelEventName";
import {
    SedestralMachine
} from "../../sedestral/framework/machine/SedestralMachine";
import {EntityService} from "../../services/entity/EntityService";

export class NetworkSocket {

    public host: string;
    public socket: NetworkSocketConnection;

    public logout: boolean;
    public events: { event: string, func: (data: any) => void }[];


    constructor(host: string) {
        this.host = host;
        this.events = [];
    }

    public async start(options: INetworkSocket): Promise<boolean> {
        return SedestralMachine.promise(async (started) => {
            let success = await this.startConnectionTry(options, 0);
            if (success) {
                this.socket.on("disconnect", () => {
                    if (!this.logout) {
                        this.onDisconnect();
                    }
                });
            }

            if (success) {
                this.events.forEach(value => this.on(value.event, value.func));
            }

            started(success);
        });
    }

    public async startConnection(options: INetworkSocket): Promise<boolean> {
        return SedestralMachine.promise((resolve) => {
            try {
                this.logout = false;
                this.socket = new NetworkSocketConnection(options, this.host);

                this.socket.on("connect", () => resolve(true));
                this.socket.on("error", () => resolve(false));
            } catch (e) {
                resolve(false);
            }
        });
    }

    public async startConnectionTry(options: INetworkSocket, attempts: number): Promise<boolean> {
        return SedestralMachine.promise(async (resolve) => {
            if (attempts < 3) {
                attempts++;
                let connected = await this.startConnection(options);
                if (connected) {
                    this.on(WebSocketPanelEventName.CONNECTION_ERROR, async () => {
                        await SedestralMachine.sleep(1000);
                        resolve(this.startConnectionTry(options, attempts));
                    });
                    this.on(WebSocketPanelEventName.CONNECTION_READY, (data) => {
                        EntityService.activeEntity.active = data;
                        resolve(true);
                    });
                } else {
                    await SedestralMachine.sleep(1000);
                    resolve(this.startConnectionTry(options, attempts));
                }
            } else {
                resolve(false);
            }
        });
    }


    public disconnect(logout?: boolean): void {
        this.socket.disconnect();
        this.socket = undefined;
        this.logout = logout;
    }

    public emit(event: string, value: any) {
        this.socket.emit(event, value);
    }

    public async emitSync(event: string, value: any) {
        return await this.socket.emitSync(event, value);
    }

    public on(event: string, func: (data: any) => void) {
        if (!this.events.find(value => value.event == event)) {
            this.events.push({event: event, func: func});
        }

        if (this.socket) {
            this.socket.on(event, func);
        }
    }

    public async join(data: any, route: string): Promise<any> {
        if (data != null) {
            return await this.emitSync(route, data);
        }
    }

    public removeListener(event: string) {
        this.socket.removeListener(event);
    }

    /**
     * override
     */

    onDisconnect() {

    }
}