import {
    StaticComponent
} from "../../../../../sedestral/router/static/component/StaticComponent";
import * as s from "./two-factor.scss";
import {TwoFactorModalComponent} from "../../components/twofactor/modal/TwoFactorModalComponent";

export class TwoFactorStatic extends StaticComponent {

    onSuccess: (response: any) => void;
    onClose: () => void;

    constructor() {
        super();
        this.virtual(s.staticTwoFactor);
    }

    start(func: (response: any) => void, cancel?: () => void) {
        this.onSuccess = (response) => {
            func(response);
            this.clear();
        }

        this.onClose = () => {
            if (cancel) {
                cancel();
            }
            this.clear();
        }
    }

    create(email: string, response: any) {
        let twoFactor = new TwoFactorModalComponent(email, response);
        twoFactor.screenRender();
        twoFactor.onClose = () => {
            if (this.onClose) {
                this.onClose();
            }
            this.clear();
        }
    }

    clear() {
        this.onSuccess = undefined;
        this.onClose = undefined;
    }
}